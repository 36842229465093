import React, { useState } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";
import './ApplicantDashboard.css';
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant';
import { Routes, Route, useLocation } from 'react-router-dom';
import { getBaseURLTonavigatetoLogin } from '../../EnvoirnmentSetup/profiles';
import { landingPage, LoginAutonavigateEnv, PortalUserRole } from '../../EnvoirnmentSetup/APIs';
import { useSelectedCardContext } from '../../Context/Context';

const ApplicantDashboard = () => {

    const locationData = useLocation()
    let path = locationData.pathname;
    const { setIsLoading, setWarning, setAlert } = useSelectedCardContext()
    global.alert = setWarning

    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    React.useEffect(() => {
        validateUser()
    }, [path]);

    const validateUser = () => {
        let userRole = JSON.parse(sessionStorage.getItem('user'))?.roles[0]?.name
        // const token = sessionStorage.getItem('token');
        // if (!!!token) {
        //     let url = window.location.href;
        //     if (url.toLowerCase().indexOf('/main/jobdetails/') > -1) {
        //         let urlData = url.split('/');
        //         // console.log('......', urlData[urlData.length - 1]);
        //         sessionStorage.setItem('jobId', urlData[urlData.length - 1])
        //     }
        //     navigate('/login')
        // }
        // else 
        if (userRole != PortalUserRole) {
            if (!userRole) return
            let WebPath = window.location.href
            window.location.href = getBaseURLTonavigatetoLogin(LoginAutonavigateEnv, userRole) + '#' + WebPath.split('#')[1]
        }
    }

    return (
        <div className='applicantDashboardSection'>
            <div className='applicantDashboardMainDiv'>
                <div aria-label="Toggle Sidebar Container" id="default-sidebar" className={`applicantDashboardSidebar sidebar ${isSidebarOpen ? 'visible' : 'hidden'}`}>
                    <div className="closeBtnDiv">
                        <button onClick={closeSidebar} id="close" className="close-btn">X</button>
                    </div>
                    <ComponentAndScreenConstant.Sidebar onClick={closeSidebar} />
                    <span className='allReserverd' style={{ textAlign: 'center' }}>© {new Date().getFullYear()} CampusPlacement.in <br />All rights reserved.</span>
                    <div className='footerLinks'>
                        <button onClick={() => { window.open(landingPage + 'termsAndCondition') }}>Terms & Conditions</button>
                        <button onClick={() => { window.open(landingPage + 'privacyPolicy') }}>Privacy Policy</button>
                    </div>
                </div>
                <div className='applicantDashboardContentDiv'>
                    <button onClick={toggleSidebar} id="toggle" className="e-btn toggleBtn" style={{ zIndex: 9 }} aria-label="Toggle Sidebar">
                        <RxHamburgerMenu />
                    </button>
                    <ComponentAndScreenConstant.Header />
                    {locationData?.state?.headerHide || window.location.href.toLowerCase().includes('jobdetails') ? <></> : <>
                        <ComponentAndScreenConstant.HeroSection />
                    </>}
                    <Routes>
                        <Route path='/latestJob' element={<ComponentAndScreenConstant.Dashboard />} />
                        <Route path='/suggested' element={<ComponentAndScreenConstant.Dashboard />} />
                        <Route path='/applied' element={<ComponentAndScreenConstant.Dashboard />} />
                        <Route path='/quikhire_jobs' element={<ComponentAndScreenConstant.Dashboard />} />
                        <Route path='/jobDetails' element={<ComponentAndScreenConstant.JobDetails />} />
                        <Route path='/jobStatus' element={<ComponentAndScreenConstant.JobDetails />} />
                        <Route path='/jobDetails/:jobId' element={<ComponentAndScreenConstant.JobDetails />} />
                        <Route path='/userProfile' element={<ComponentAndScreenConstant.UserProfile />} />
                        <Route path='/SimilarJobs' element={<ComponentAndScreenConstant.SimilarJobs />} />
                        <Route path='/companyPostedJob' element={<ComponentAndScreenConstant.SimilarJobs />} />
                        <Route path='/recruiterListedJobs' element={<ComponentAndScreenConstant.SimilarJobs />} />
                        <Route path='/recruiterInfo' element={<ComponentAndScreenConstant.RecruiterInfo />} />
                        <Route path='/notification' element={<ComponentAndScreenConstant.Notification />} />
                        <Route path='/myResume' element={<ComponentAndScreenConstant.MyResume />} />
                        <Route path='/reports' element={<ComponentAndScreenConstant.Reports />} />
                        <Route path='/*' element={<div style={{ width: '100%', height: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', fontWeight: 600, fontSize: '2rem' }}>Page Not Found</div>} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default ApplicantDashboard;
