import './TextArea.css'
import React from 'react'

const TextArea = ({
  labelTxt,
  valueText,
  onChange,
  txtAreaStyle,
  textareaClass,
  txtAreaWrapperStyle,
  errMsg,
  placeholderTxt,
  isRequired = false,
  maxLength = 1000
}) => {
  return (
    <div className={txtAreaWrapperStyle} style={{ display: "flex", flexDirection: "column" }}>
      {!!labelTxt && <label>{labelTxt}{isRequired && <small style={{ color: "#BC0000" }}>*</small>}</label>}
      {/* <input type="text" value={valueText}/> */}
      <textarea placeholder={placeholderTxt} className={textareaClass} maxLength={maxLength} style={{ resize: "none", ...txtAreaStyle }} onChange={onChange} value={valueText}>{valueText}</textarea>
      <div className='errmsgField'>
        {<small className='errorMessageShow'>{!!errMsg ? errMsg : ""}</small>}
        <small style={{ color: "#a7a7a7", textAlign: "right" }}>{valueText?.length ?? 0}/{maxLength}</small>
      </div>
    </div>
  )
}

export default TextArea