import './AddField.css'
import React from 'react'
import addIcon from '../../../../assets/AddIcon.svg'

const AddField = ({
  addFieldWrapper,
  fieldTxt,
  onClick
}) => {
  return (
    <div className={`${addFieldWrapper} addFieldButtonDiv`} onClick={onClick}>
      <span><img src={addIcon} alt='' /> {fieldTxt}</span>
    </div>
  )
}

export default AddField