import moment from 'moment';
import './MobileResumePreview.css'
import React, { useEffect, useState } from 'react'

import { FaMobileAlt } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import { FaPlayCircle } from "react-icons/fa";

import expIcon from '../../../../assets/exp.svg'
import emailIcon from '../../../../assets/emailIcon.svg'
import UserIcon from '../../../../assets/userNewLogo.svg'
import mobileIcon from '../../../../assets/mobileIcon.svg'
import locationIcon from '../../../../assets/locationIcon.svg'

const MobileResumePreview = ({ allResumeDetails, userAction, base64Img, audioFile }) => {
  const shouldRenderDivExp = (allResumeDetails?.experiences)?.some(item => !!item.companyName || !!item.profileSummary || !!item.designation.designationName);
  const shouldRenderDivIntern = (allResumeDetails?.internships)?.some(item => !!item.companyName || !!item.profileSummary || !!item.designation.designationName);
  const shouldRenderDivEducation = (allResumeDetails?.educations)?.some(item => !!item.degree || !!item.startYear || !!item.endYear || !!item.university);
  const shouldRenderCertificationDiv = (allResumeDetails?.certifications)?.some(item => !!item.certificateName || !!item.startDate || !!item.endDate)
  const shouldRenderReferralDiv = (allResumeDetails?.referrals)?.some(item => !!item.referrerName || !!item.mobileNumber || !!item.email)
  const totalExperience = /year(s)?/i.test(allResumeDetails?.totalExperience)
    ? allResumeDetails?.totalExperience
    : allResumeDetails?.totalExperience + " Years";
  const skills = allResumeDetails?.skills;
  const half = Math.ceil(skills?.length / 2);
  const firstHalf = skills?.slice(0, half);
  const secondHalf = skills?.slice(half);
  const [profileImage, setProfileImage] = useState('')
  useEffect(() => {
    let urlImage = '';

    if (allResumeDetails?.profileUrl?.name) {
      urlImage = URL.createObjectURL(allResumeDetails.profileUrl);
      setProfileImage(urlImage);
    } else if (base64Img) {
      setProfileImage(`data:image/png;base64,${base64Img}`);
    } else {
      setProfileImage('');
    }

    return () => {
      if (urlImage) {
        URL.revokeObjectURL(urlImage);
      }
    };
  }, [allResumeDetails, base64Img]);


  const currentExperience = allResumeDetails?.experiences?.find(exp => exp.currentlyWorking);

  let nearestLastExperience = currentExperience;

  if (!nearestLastExperience) {
    nearestLastExperience = allResumeDetails?.experiences?.filter(exp => !exp.currentlyWorking)
      .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))?.[0];
  }

  let currentEducationDegree = "";
  let currentEducationCollege = "";
  let deepCopyAllresumeObject = JSON.parse(JSON.stringify(allResumeDetails));
  if (!currentEducationDegree || !currentEducationCollege) {
    const nearestLastEducation = deepCopyAllresumeObject?.educations?.sort((a, b) => new Date(b.endYear) - new Date(a.endYear))?.[0];
    currentEducationDegree = nearestLastEducation?.degree || "";
    currentEducationCollege = nearestLastEducation?.university || "";
  }

  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    popup: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    closeButton: {
      alignSelf: 'flex-end',
      background: 'none',
      border: 'none',
      fontSize: '16px',
      cursor: 'pointer',
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: '10px',
      width: "100%"
    },
    headerTitle: {
      display: "block",
      // margin: "10px 0",
      fontSize: "1rem",
      fontWeight: "600",
      color: "#01774a",
    }
  }
  return (
    <div className={`${userAction == "resumeView" ? "mobileResumeViewMainDiv" : "mobileResumePreviewMainDiv"}`}>
      {/* {audioFile && <a href={audioFile} target="_blank"><FaPlayCircle /></a>} */}
      {audioFile &&
        <button onClick={openPopup} className='playAudio' style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <FaPlayCircle size={30} />
          Audio
        </button>}
      <div className={`${profileImage ? 'mainProfileSectionWithImg' : "mainProfileSection"}`} style={{ borderBottom: !!allResumeDetails?.firstName ? '1px solid' : 'none', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        {!!profileImage && <img src={profileImage} alt="Something went wrong." onError={(e) => e.target.src = UserIcon} />}
        {!!allResumeDetails?.firstName && <div className={`resumerNameValue`}>
          {allResumeDetails?.firstName}
        </div>
        }
      </div>
      <div className='contactInformation'>
        <table className='contactInfoTable'>
          <thead style={{ display: 'none' }}>
            <tr>
              <th>
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody className='mainContactWrapper'>
            <tr>
              {!!allResumeDetails?.mobileNumber && <td className='contactNo'><span className='reactIcon'>
                <img src={mobileIcon} alt='Something went wrong' />
              </span> <div className='overflowAdding'>
                  +{allResumeDetails?.countryCode?.countryCode} {allResumeDetails?.mobileNumber}
                </div></td>}
              {!!allResumeDetails?.email && <td className='contactEmail'><span className='reactIcon'>
                <img src={emailIcon} alt='Something went wrong' />
              </span>  <div className='overflowAdding'>
                  {allResumeDetails?.email}
                </div></td>}
            </tr>
            <tr>
              {!!allResumeDetails?.city?.cityName && <td className='contactAddress'><span className='reactIcon'>
                <img src={locationIcon} alt='Something went wrong' />
              </span>
                <div className='overflowAdding'>
                  {allResumeDetails?.city?.cityName?.trim() + ', ' + allResumeDetails?.state?.trim()}
                </div></td>}
              {allResumeDetails?.experienceStatus && allResumeDetails?.totalExperience != "" && <td className='contactEmail'><span className='reactIcon'>
                <img src={expIcon} alt='Something went wrong' />
              </span>  <div className='overflowAdding'>
                  {totalExperience}
                </div></td>}
            </tr>
          </tbody>
        </table>
      </div>
      <div className='profileSummaryDiv'>
        {allResumeDetails?.skills?.length != 0 && <div className='blockHeading'>
          Professional Summary
        </div>}
        {allResumeDetails?.skills?.length != 0 && allResumeDetails?.summary != "" && allResumeDetails?.experienceStatus && allResumeDetails?.totalExperience && nearestLastExperience?.designation?.designationName ?
          <>
            <h3 className='skillHeading'>Summary:</h3>
            <p className='personalSummaryShow'>
              {allResumeDetails?.summary}
            </p></>
          : <></>}
        {allResumeDetails?.skills?.length != 0 && allResumeDetails?.summary != "" && !allResumeDetails.experienceStatus && currentEducationDegree && currentEducationCollege ?
          <>
            <h3 className='skillHeading'>Summary:</h3>
            <p className='personalSummaryShow'>
              {allResumeDetails?.summary}
            </p></>
          : <></>}
        {allResumeDetails?.skills?.length != 0 && <h3 className='skillHeading'>Skills:</h3>}
        <div className='skillDetails'>
          <ul className="skills-column">
            {firstHalf?.map((item, index) => (
              <li key={index} className="skillItem">
                <span className="skillValue">{item.name}</span>
              </li>
            ))}
          </ul>
          <ul className="skills-column">
            {secondHalf?.map((item, index) => (
              <li key={index} className="skillItem">
                <span className="skillValue">{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {shouldRenderDivIntern && (allResumeDetails.isIntern || allResumeDetails.expWithIntern) && <div>
        <div className='blockHeading'>
          Internship Details
        </div>
        <div className='experienceDetailsAll'>
          {(allResumeDetails?.internships)?.map((item, index) => {
            return (
              <>
                {item?.companyName || item?.designation?.designationName || item?.profileSummary ? <div className="experienceDetailsSeperate" key={index}>
                  <div className='expDetails'>
                    {item?.designation?.designationName && <div className='designation'>{item?.designation?.designationName}</div>}
                    <div className='expDates'>
                      {`${!!item?.startDate ? moment(item?.startDate).format('MMM-YYYY') : ''}${item?.currentlyWorking ? " To Present" : ""}${!!item?.endDate ? (!!item?.startDate && !!item?.endDate ? " To " : '') + moment(item?.endDate).format('MMM-YYYY') : ''}`}
                    </div>
                  </div>
                  {item?.companyName && <div className='companyName '>{item?.companyName}</div>}
                  {item?.profileSummary && <h5 className='roleHeading'>Responsibilities and Achievements:</h5>}
                  {item?.profileSummary && <p className='personalSummaryShow'>{item?.profileSummary} </p>}
                </div> : <></>}</>)
          })}
        </div>
      </div>}
      {shouldRenderDivExp && (allResumeDetails.experienceStatus || allResumeDetails.expWithIntern) && <div>
        <div className='blockHeading'>
          Experience
        </div>
        <div className='experienceDetailsAll'>
          {(allResumeDetails?.experiences)?.map((item, index) => {
            return (
              <>
                {item?.companyName || item?.designation?.designationName || item?.profileSummary ? <div className="experienceDetailsSeperate" key={index}>
                  <div className='expDetails'>
                    {item?.designation?.designationName && <div className='designation'>{item?.designation?.designationName}</div>}
                    <div className='expDates'>
                      {`${!!item?.startDate ? moment(item?.startDate).format('MMM-YYYY') : ''}${item?.currentlyWorking ? " To Present" : ""}${!!item?.endDate ? " To " + moment(item?.endDate).format('MMM-YYYY') : ''}`}
                    </div>
                  </div>
                  {item?.companyName && <div className='companyName '>{item?.companyName}</div>}
                  {item?.profileSummary && <h5 className='roleHeading'>Responsibilities:</h5>}
                  {item?.profileSummary && <p className='personalSummaryShow'>{item?.profileSummary} </p>}
                </div> : <></>}
              </>)
          })}
        </div>
      </div>}
      {shouldRenderCertificationDiv || shouldRenderDivEducation ? <div className='blockHeading'>
        Qualification
      </div> : ""}
      {shouldRenderDivEducation &&
        <div>
          <h3 className='skillHeading'>Education:</h3>
          <div className='educationAndCertificationAll mbottomEdu'>
            {(allResumeDetails.educations)?.map((item) => {
              return (
                <div className="educationAndCertificationSeperate">
                  <div className='educationDetails'>
                    {item?.degree && <div className='degreeName '>  {item?.degree}</div>}
                    <div className='degreeDtae'>
                      {`${!!item?.startYear ? moment(item?.startYear).format('YYYY') : ''}${!!item?.endYear ? " - " + moment(item?.endYear).format('YYYY') : ''}`}
                    </div>
                  </div>
                  {item?.university && <div className='collageName '>{item?.university}</div>}
                </div>
              )
            })
            }
          </div>
        </div>
      }
      <div className='educationAndCertificationAll'>
        {shouldRenderCertificationDiv && <h3 className='skillHeading'>Certification:</h3>}
        {shouldRenderCertificationDiv && (allResumeDetails?.certifications)?.map((item) => {
          return (
            <>{
              item?.certificateName || item?.endDate || item?.instituteName ?
                <div className="educationAndCertificationSeperate">
                  <div className='certificationDetails'>
                    {item?.certificateName && <div className='certificationName'> {item?.certificateName}</div>}
                    <div className='certificationDtae'>
                      {item?.endDate && moment(item?.endDate).format('MMM-YYYY')}
                    </div>
                  </div>
                  {item?.instituteName && <div className='collageName '>{item?.instituteName}</div>}
                </div> : <></>
            }
            </>
          )
        })}
      </div>
      <div className='educationAndCertificationAll'>
        {allResumeDetails?.hireMeQuestion != "" &&
          <div className='blockHeading'>
            Why Should You Hire Me?
          </div>}
        {allResumeDetails?.hireMeQuestion != "" && <p className='personalSummaryShow'>
          {allResumeDetails?.hireMeQuestion}
        </p>}
        {allResumeDetails?.skills?.length != 0 && allResumeDetails?.summary != "" && allResumeDetails?.languages?.length != 0 &&
          <div className='blockHeading'>
            Languages
          </div>}
        {allResumeDetails?.skills?.length != 0 && allResumeDetails?.summary != "" && allResumeDetails?.languages?.length != 0 && <div className='skillDetails'>{(allResumeDetails.languages)?.map((item) => {
          return (
            <div className='skillItems'>{item.languageName}</div>
          )
        })}
        </div>}
        {shouldRenderReferralDiv && <>
          <div className='blockHeading'>Referral Details</div>
          <div className="educationAndCertificationSeperate">
            <div className='referenceDetail'>
              <div className='referralName '>Referral Name</div>
              <div className='referralMobile'>Mobile Number</div>
              <div className='referralEmail'>Email ID</div>
            </div>
          </div>
          <div className='referalDetailMainDiv referalDetailMainDivValues'>
            {(allResumeDetails?.referrals)?.map((item, index) => {
              return (
                <>
                  {item?.referrerName || item?.mobileNumber || item?.email ? <div className="educationAndCertificationSeperate">
                    <div className='referenceDetailValues'>
                      {item?.referrerName ? <div className='referralNameValue'> {item?.referrerName}</div> : <div className='referralNameValue' style={{ textAlign: 'center' }}>-</div>}
                      {item?.mobileNumber ? <div className='referralMobileValue'>  +{item?.countryCode?.countryCode} {item?.mobileNumber}</div> : <div className='referralMobileValue' style={{ textAlign: 'center' }}>-</div>}
                      {item?.email ? <div className='referralEmailValue'> {item?.email}</div> : <div className='referralEmailValue' style={{ textAlign: 'center' }}>-</div>}

                    </div>
                  </div> : <></>}
                </>
              )
            })}
          </div></>}
      </div>
      {isOpen && (
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <div style={styles.header}>

              <span style={styles.headerTitle}>Play Audio</span>
              <button onClick={closePopup} style={styles.closeButton}>X</button>
            </div>
            <audio controls autoPlay>
              <source src={audioFile} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      )}
      {/* {allResumeDetails?.summaryAudio && <div>
        { !sessionStorage.getItem('hideAudio')? <div style={{display:"flex",margin:"10px auto",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"5px"}}>
          <h3 style={{fontWeight:"normal",fontSize:"1rem",margin:"0px"}}>Audio Preview:</h3>
          <audio controls>
            <source src={allResumeDetails?.summaryAudio?.name? URL.createObjectURL(allResumeDetails?.summaryAudio) : allResumeDetails?.summaryAudio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>:<></>}
      </div>} */}
    </div>
  )
}

export default MobileResumePreview