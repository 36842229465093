import React, { useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelectedCardContext } from '../../Context/Context';
import { ModuleCards } from '../../constants/ModuleCards';

import QuikHire from '../../assets/quikHireLogo.png';
import reportsIcon from '../../assets/reportsIcon.svg';
import myResumeIcon from '../../assets/myResumeIcon.svg';
import appliedGrayIcon from '../../assets/appliedGrayIcon.svg';
import reportsIconWhite from '../../assets/reportsIconWhite.svg';
import appliedActiveIcon from '../../assets/appliedActiveIcon.svg';
import myResumeIconWhite from '../../assets/myResumeIconWhite.svg';
import suggestedGrayIcon from '../../assets/suggestedGrayIcon.svg';
import dashboardGrayIcon from '../../assets/dashboardGrayIcon.svg';
import suggestedActiveIcon from '../../assets/suggestedActiveIcon.svg';
import dashboardActiveIcon from '../../assets/dashboardActiveIcon.svg';

const Sidebar = ({ onClick }) => {
    const navigate = useNavigate()
    const location = useLocation();
    let path = location.pathname;
    const { selectedCard, setSelectedCard } = useSelectedCardContext()

    const [userData, setUserData] = React.useState()

    useEffect(() => {
        setUserData(JSON.parse(sessionStorage.getItem("user")))
        const token = sessionStorage.getItem('token');
        let url = window.location.href;
        if (!!!token) {
            if (url.toLowerCase().indexOf('/main/jobdetails/') > -1) {
                let urlData = url.split('/');
                // console.log('......', urlData[urlData.length - 1]);
                sessionStorage.setItem('jobId', urlData[urlData.length - 1])
            }
            navigate('/login')
        }
    }, [selectedCard])

    // useEffect(() => {
    //     if (path.includes("/latestJob")) {
    //         setSelectedCard(1);
    //     } else if (path.includes("/suggested") || path.includes("/suggestedPref") || path.includes("/notification")) {
    //         setSelectedCard(2);
    //     } else if (path.includes("/applied")) {
    //         setSelectedCard(3);
    //     } else if (path.includes("/myResume")) {
    //         setSelectedCard(4);
    //     } else if (path.includes("/reports")) {
    //         setSelectedCard(5);
    //     } else {
    //         // setSelectedCard(1);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [path]);


    const handleActiveCard = (jobType) => {
        onClick()
        switch (jobType) {
            case 'latestJobs': setSelectedCard(1);
                break;
            case 'QuikHireJobs': setSelectedCard(6);
                break;
            case 'suggestedJobs': setSelectedCard(2);
                break;
            case 'appliedJobs': setSelectedCard(3)
                break;
            case 'myResume': setSelectedCard(4)
                break;
            case 'reports': setSelectedCard(5)
                break;
            default: setSelectedCard(1);
                break;
        }
    }
    return (
        <>
            <div className='quikHireLogoDiv'>
                <NavLink to="/Main/latestJob" state={{ screen: 'Campus' }} onClick={() => handleActiveCard('latestJobs')} >
                    <img src={QuikHire} alt="QuikHire Logo" className='quikHireLogo' />
                </NavLink>
            </div>
            <ul className='linkingMenu'>
                <li>
                    <NavLink to="/Main/latestJob" state={{ screen: 'Campus' }} onClick={() => handleActiveCard('latestJobs')} className={selectedCard == ModuleCards?.LatestJobs ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.LatestJobs ? dashboardActiveIcon : dashboardGrayIcon} alt="Dashboard Icon" /> Campus Jobs
                            </>
                        )}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/Main/applied" state={{ screen: 'applied' }} onClick={() => handleActiveCard('appliedJobs')} className={selectedCard == ModuleCards?.AppliedJobs ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.AppliedJobs ? appliedActiveIcon : appliedGrayIcon} alt="Applied Icon" />Campus Applied Jobs
                            </>
                        )}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/Main/reports" state={{ headerHide: false }} onClick={() => handleActiveCard('reports')} className={selectedCard == ModuleCards?.reports ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.reports ? reportsIconWhite : reportsIcon} alt="reports Icon" /> My Reports
                            </>
                        )}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/Main/myResume" state={{ headerHide: false }} onClick={() => handleActiveCard('myResume')} className={selectedCard == ModuleCards?.myResume ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.myResume ? myResumeIconWhite : myResumeIcon} alt="myResume Icon" /> My Resume
                            </>
                        )}
                    </NavLink>
                </li>
                <div style={{width:'90%',borderBottom:'1px solid #007749',color:'#007749',margin:'2px auto 0 ',fontSize:'10px',padding:'0 0 0 10px'}}>
                    {/* QuikHire  */}
                </div>
                <li>
                    <NavLink to="/Main/quikhire_jobs" state={{ screen: 'QuikHire' }} onClick={() => handleActiveCard('QuikHireJobs')} className={selectedCard == ModuleCards?.QuikHire ? 'activeList' : ''}>
                        {({ isActive }) => (
                            <>
                                <img src={selectedCard == ModuleCards?.QuikHire ? appliedActiveIcon : appliedGrayIcon} alt="QuikHire Icon" /> QuikHire Jobs
                            </>
                        )}
                    </NavLink>
                </li>
            </ul>
        </>
    )
}

export default Sidebar