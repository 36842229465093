import { ProfileConstant, RoleConstant } from "./ProfileConstants";
import { ApiProfile, ResumeBuilderAPIProfile, ResumeBuilderWebProfile, StudentDeepLinking, campusLandingPage, getWebProfileForDeepLinking } from "./profiles";

export const PortalUserRole = RoleConstant.ROLE_APPLICANT
export const LoginAutonavigateEnv = ProfileConstant.ProdApplicantV1;

const ResumeURL = ResumeBuilderAPIProfile.ProdV1
export const ResumeWebURL = ResumeBuilderWebProfile.ProdV1
//deeplink redirect
export const DynamicLink = StudentDeepLinking?.ProdV1
export const landingPage = campusLandingPage.ProdV1

const baseURL = ApiProfile.ProdApplicantV1

export const Url = {
  CountryCodes: baseURL + "countrycodes",
  registerUser: baseURL + 'user/sign-up',
  SendOtp: baseURL + "sms/otp",
  UserLogin: baseURL + "login",
  getuser: baseURL + "user",
  otpVerify: baseURL + 'sms/otp/varify',
  mobileNoVerification: baseURL + 'public/user/countrycode/{countrycodeId}/username/{mobile_number}/iam/' + PortalUserRole,
  forgotPwd: baseURL + 'user/forgot-password',
  getAllJobs: baseURL + 'job/latest',
  getAllKeywords: baseURL + 'skill',
  GetExperienceList: baseURL + 'public/experience',
  GetJobType: baseURL + 'job/job-type',
  GetWorkMode: baseURL + 'job/work-mode',
  getAllLocation: baseURL + 'state',
  submitPrefernece: baseURL + 'user/preference',
  GetAllFilterdJob: baseURL + 'user/job/filter',
  getUserPrefernces: baseURL + 'user/preference',
  getJobDetailsByJobId: baseURL + 'job/{id}',
  GetAllSimilarJobs: baseURL + 'job/{jobId}/similar',
  GetJobsByCompanyUid: baseURL + 'company/{companyUid}/jobs',
  postApplyJob: baseURL + 'user/job',
  GetAllInterestedJob: baseURL + 'user/job/intrested',
  getJobDetailsByJobIDApplicant: baseURL + 'user/job/{jobID}',
  changePassword: baseURL + 'user/update-password',
  GetNotifications: baseURL + "notification",
  getNotificationCount: baseURL + "notification/count",
  DeleteRejectedApplications: baseURL + 'user/job/status/{userJobId}',
  GetJobsByRecruiterId: baseURL + 'job/user/{userId}',
  SendFeedBack: baseURL + 'feedback',
  GetPostApplicationStatus: baseURL + 'job/{jobId}/applicant',
  GetUserReports: baseURL + 'user/job/reports/{userId}',
  GetUserReportsByDate: baseURL + 'user/job/reports/{userId}/from/{fromDate}/to/{toDate}',
  GetResume: baseURL + 'user/resume/{mobile}/country/{countryCodeId}',

  DownloadReports: baseURL + 'user/student/excel/{key}/{userId}',
  DownloadReportsByDate: baseURL + 'user/student/excel/{key}/from/{fromDate}/to/{toDate}/{userId}',
  QuikHireJobsStatus: baseURL + 'user/quik-hire/jobview',
  GetQuikHireJobs: baseURL + 'job/quik-hire',

  //payment methods api urls
  getKeyAndPricing: baseURL + 'pricing', //GET api to get encode and decode key
  getOrderIdAndUpdatePaymentStatus: baseURL + 'payment/order', //POST for Generate OrderId and PUT for Order Status 
  paymentSuccessStatus: baseURL + 'student/purchase-license', //POST
  validatePromoCode: baseURL + 'promo/{promocode}', //GET api to get promo code discount
  getAllTransactionHistory: baseURL + "student/history",
  getInstituteInfo : baseURL + "institute/student/{userId}"
};

export const ResumeApiUrls = {
  GetResume: ResumeURL + 'user/resume/{mobile}/country/{countryCodeId}',
  GetResumeByUid: ResumeURL + 'user/resume/{userUid}',
} 

export const ResumeApi = {
  getAllSkills: ResumeURL + "skill/",
  getAllLanguages: ResumeURL + "language/",
  getAllDesignation: ResumeURL + "designation/",
  getAllCities: ResumeURL + "city/country/{countryCodeId}/",
  // submitResumeDetails: ResumeURL + "resume", //prod 
  submitResumeDetails: ResumeURL + "resume/create", //int and uat
  editResumeDetails: ResumeURL + "resume/edit", //int and uat
  // getResumeByUserID: ResumeURL + "resume/user/{userUid}", //prod
  getResumeByUserID: ResumeURL + "user/resume/{userUid}", //int and uat
  getCountryCode: ResumeURL + 'country',
  mobileNoVerification: ResumeURL + 'public/user/countrycode/{countrycode}/username/{mobileNumber}',
  emailVerification: ResumeURL + 'public/user/{email}',
  sendOTP: ResumeURL + 'sms/otp',
  forgotPwd: ResumeURL + 'user/forgot-password',
  UserLogin: ResumeURL + 'login',
  getuser: ResumeURL + "user/dashboard",
  UploadDocumentToBucket : ResumeURL + 's3/public/{folderName}/upload',
  checkUserUid : ResumeURL + "public/user/uid/{uid}",
  privateCheckUserUid : ResumeURL + "user/resume/uid/{uid}"
}