export const protocol = 'http'

export const ApiProfile = {
    DevApplicantV1: `${protocol}://52.66.72.6:8080/campus-placement-dev/api/v1/`,
    IntApplicantV1: `${protocol}://52.66.72.6:8080/campus-placement-int/api/v1/`,
    UatApplicantV1: `${protocol}://52.66.72.6:8080/campus-placement-uat/api/v1/`,
    ProdApplicantV1: 'https://student.campusplacement.in/campus-placement/api/v1/',
}

export const WebProfile = {
    DevRecruiterV1: `${protocol}://52.66.72.6:8080/campus_dev_coordinator/`,
    IntRecruiterV1: `${protocol}://52.66.72.6:8080/campus_int_coordinator/`,
    UatRecruiterV1: `${protocol}://52.66.72.6:8080/campus_uat_coordinator/`,
    ProdRecruiterV1: 'https://co.campusplacement.in/',

    DevLeaderV1: `${protocol}://52.66.72.6:8080/campus_dev_admin/`,
    IntLeaderV1: `${protocol}://52.66.72.6:8080/campus_int_admin/`,
    UatLeaderV1: `${protocol}://52.66.72.6:8080/campus_uat_admin/`,
    ProdLeaderV1: 'https://admin.campusplacement.in/',

    DevAdminV1: `${protocol}://52.66.72.6:8080/campus_dev_admin/`,
    IntAdminV1: `${protocol}://52.66.72.6:8080/campus_int_admin/`,
    UatAdminV1: `${protocol}://52.66.72.6:8080/campus_uat_admin/`,
    ProdAdminV1: 'https://admin.campusplacement.in/',

    DevApplicantV1: `${protocol}://52.66.72.6:8080/campus_dev_student/`,
    IntApplicantV1: `${protocol}://52.66.72.6:8080/campus_int_student/`,
    UatApplicantV1: `${protocol}://52.66.72.6:8080/campus_uat_student/`,
    ProdApplicantV1: 'https://student.campusplacement.in/',
}

export const StudentDeepLinking = {
    DevV1: `${protocol}://52.66.72.6:8080/campus_dev_student/#/`,
    IntV1: `${protocol}://52.66.72.6:8080/campus_int_student/#/`,
    UatV1: `${protocol}://52.66.72.6:8080/campus_uat_student/#/`,
    ProdV1: 'https://student.campusplacement.in/#/',
}

export const ResumeBuilderAPIProfile = {
    DevV1: `${protocol}://localhost:8080/`,
    IntV1: `${protocol}://13.52.157.11:8080/resume-builder/api/v1/`,
    UatV1: `${protocol}://13.52.157.11:8080/resume-builder/api/v1/`,
    ProdV1: 'https://api.shareresume.co/api/v1/'
}

export const ResumeBuilderWebProfile = {
    DevV1: `${protocol}://13.52.157.11:8080/resume_builder_int/#/`,
    IntV1: `${protocol}://13.52.157.11:8080/resume_builder_int/#/`,
    UatV1: `${protocol}://13.52.157.11:8080/resume_builder_uat/#/`,
    ProdV1: 'https://www.shareresume.co/#/',
}

export const campusLandingPage = {
    DevV1: `${protocol}://52.66.72.6:8080/campus_placement_dev/#/`,
    IntV1: `${protocol}://52.66.72.6:8080/campus_placement_int/#/`,
    UatV1: `${protocol}://52.66.72.6:8080/campus_placement_uat/#/`,
    ProdV1: 'https://campusplacement.in/#/',
}

export const getBaseURLTonavigatetoLogin = (profile, role) => {
    if (profile.toLocaleLowerCase().includes('dev')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.DevRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.DevLeaderV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.DevAdminV1
            return (dataURL);
        } else if (role == "ROLE_APPLICANT") {
            let dataURL = WebProfile.DevApplicantV1
            return (dataURL);
        }
    }
    if (profile.toLocaleLowerCase().includes('int')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.IntRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.IntLeaderV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.IntAdminV1
            return (dataURL);
        } else if (role == "ROLE_APPLICANT") {
            let dataURL = WebProfile.IntApplicantV1
            return (dataURL);
        }
    }

    if (profile.toLocaleLowerCase().includes('uat')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.UatRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.UatLeaderV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.UatAdminV1
            return (dataURL);
        } else if (role == "ROLE_APPLICANT") {
            let dataURL = WebProfile.UatApplicantV1
            return (dataURL);
        }
    }
    if (profile.toLocaleLowerCase().includes('prod')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.ProdRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.ProdLeaderV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.ProdAdminV1
            return (dataURL);
        } else if (role == "ROLE_APPLICANT") {
            let dataURL = WebProfile.ProdApplicantV1
            return (dataURL);
        }
    }
}
