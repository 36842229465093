import moment from 'moment';
import '../../Mobile/Mobile.css'
import Modal from 'react-modal';
import Lottie from 'lottie-react';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import styles from './InterShipDetails.module.css';
import { ToastContainer, toast } from 'react-toastify';

import { ConstantScreen } from '../ConstantScreen';
import { ResumeApi } from '../../../../EnvoirnmentSetup/APIs';
import { getAPICall } from '../../../../APIMethods/APIMethods';

import Error from '../../../../assets/Opps.json'
import Success from '../../../../assets/Success.json'
import deleteIcon from '../../../../assets/delete.png'

const InterShipDetails = ({ data, setData, designationSelectedIntern, internShipErrors, setInternShipErrors, setSubmitResume }) => {
    const [designationOptions, setDesignationOptions] = useState([]);
    const [errModal, setErrModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [modalMsg, setModalMsg] = useState("")

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            minWidth: window.screen.width > 1023 ? "30%" : "70%",
            maxWidth: window.screen.width > 1023 ? "50%" : "80%",
            borderRadius: "10px",
            padding: "10px"
        },
    };

    const handleAddExperience = () => {
        if (data.internships.length < 3) {
            setData({
                ...data,
                internships: [
                    ...data.internships,
                    {
                        companyName: null, location: null, profileSummary: null, isIntern: true, designation: {
                            designationName: null
                        }, startDate: null, endDate: null
                    }
                ]
            });
        } else {
            // toast.warning("You can add upto 3 internships")  
            setModalMsg("You can add upto 3 Internships.")
            setErrModal(true)
        }
    };

    useEffect(() => {
        getAPICall(ResumeApi.getAllDesignation)
            .then((res) => {
                const allDesignationArray = (res.DATA).map((item) => ({
                    value: item?.designationId,
                    label: item?.designationName
                }))
                setDesignationOptions(allDesignationArray)
            })
    }, [])


    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };
    const handleChange = (index, field, value) => {
        setSubmitResume(false)
        const newInterShipDetails = [...data.internships];

        if (field === "designation") {
            const designationDetails = {
                designationId: typeof value.value === 'string' ? null : value.value,
                designationName: value.label
            };
            newInterShipDetails[index][field] = designationDetails;
        } else if (field === 'startDate' || field === 'endDate') {
            if (field === "startDate") {
                newInterShipDetails[index]['endDate'] = null;
            }
            newInterShipDetails[index][field] = moment(value).format('YYYY-MM-DD');
        } else if (field === 'currentlyWorking') {
            newInterShipDetails[index][field] = value === "true";
            newInterShipDetails[index]['endDate'] = null;
        } else {
            newInterShipDetails[index][field] = value;
        }

        setData({ ...data, internships: newInterShipDetails });

        const errorMsg = validateField(field, value);
        setInternShipErrors((prevErrors) => {
            // Ensure prevErrors is an array
            const updatedErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
            if (!updatedErrors[index]) {
                updatedErrors[index] = {};
            }
            updatedErrors[index][field] = errorMsg;

            return updatedErrors;
        });
    };

    const validateField = (field, value) => {
        let errorMsg = '';

        switch (field) {
            case 'companyName':
                if (!value.trim()) {
                    errorMsg = 'Company/Institute Name cannot be empty.';
                }
                break;
            case 'startDate':
                if (!value) {
                    errorMsg = 'Internship Start Date cannot be empty.';
                }
                break;
            case 'endDate':
                if (!value) {
                    errorMsg = 'Internship End Date cannot be empty.';
                }
                break;
            case 'profileSummary':
                if (!value.trim()) {
                    errorMsg = 'Responsibilities and Achievements cannot be empty.';
                }
                break;
            case 'designation':
                if (!value) {
                    errorMsg = 'Internship Title cannot be empty.';
                }
                break;
            default:
                break;
        }

        return errorMsg;
    };

    const handleRemoveExperience = (indexToRemove) => {
        const internships = data.internships || [];
        if (indexToRemove >= 0 && indexToRemove < internships.length) {
            const newInterShipDetails = [...internships]; // Spread operator to copy array
            newInterShipDetails.splice(indexToRemove, 1);
            setData({ ...data, internships: newInterShipDetails });
        } else {
            console.error(`Invalid index (${indexToRemove}) for removing Internship. Valid range: 0 to ${internships.length - 1}`);
        }
    };
    return (
        <div className="InternShipform">
            {data?.internships?.map((exp, index) => (
                <div className="InternShipFormFields" key={index}>
                    <div className={styles.InternShipHeadingDetails}>
                        <span className={styles.InternShipCount}>Internship {index + 1}</span>
                        <button type="button" onClick={() => { handleRemoveExperience(index) }} className="removeFieldButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className='deleteField' style={{ width: "0.8rem" }} src={deleteIcon} alt='something went wrong' /></button>
                    </div>
                    <div className='webRespFieldsWrapper'>
                        <ConstantScreen.SelectField
                            labelTxt="Internship Title"
                            inputId="designation"
                            isCreatable={true}
                            selectedValue={designationSelectedIntern[index]?.label ? designationSelectedIntern[index] : 'Select....'}
                            onChange={(e) => handleChange(index, 'designation', e)}
                            inputWrapperStyle="designationWrapper"
                            selectOptions={designationOptions}
                            placeholderTxt={"Select Internship Title"}
                            menuPlacement={"auto"}
                            errMsg={internShipErrors[index]?.designationName}
                            isRequired={false}
                        />
                        <ConstantScreen.InputText
                            labelTxt='Company/Institue Name'
                            inputType='text'
                            inputId={`companyName-${index}`}
                            inputClass='companyNameInput'
                            inputStyle={{ borderRadius: '5px' }}
                            inputValue={exp.companyName}
                            onChange={(e) => handleChange(index, 'companyName', e.target.value)}
                            inputWrapperStyle='companyNameWrapper'
                            errMsg={internShipErrors[index]?.companyName}
                            placeholderTxt='Enter Company/Institue Name'
                            isRequired={false}
                        />
                        <ConstantScreen.DatePickerField
                            labelTxt='Internship Start Date'
                            inputType='date'
                            inputId={`startDate-${index}`}
                            inputClass='startDateInput'
                            inputStyle={{ borderRadius: '5px' }}
                            selectedValue={exp.startDate}
                            onChange={(e) => handleChange(index, 'startDate', e)}
                            inputWrapperStyle='startDateWrapper'
                            errMsg={internShipErrors[index]?.startDate}
                            showMonthYearPicker={true}
                            showYearPicker={false}
                            dateFormat={"MMM-yyyy"}
                            maxDate={new Date()}
                            placeholderTxt='Enter Internship Start Date'
                            isRequired={false}
                        />
                        <ConstantScreen.DatePickerField
                            disabledData={exp.currentlyWorking}
                            labelTxt='Internship End Date'
                            inputType='date'
                            inputId={`endDate-${index}`}
                            inputClass='endDateInput'
                            inputStyle={{ borderRadius: '5px' }}
                            showMonthYearPicker={true}
                            showYearPicker={false}
                            dateFormat={"MMM-yyyy"}
                            maxDate={new Date()}
                            minDate={exp.startDate}
                            // maxDate={data.internships[index - 1]?.startDate || new Date()}
                            // minDate={exp.startDate || ""}
                            selectedValue={exp.endDate}
                            onChange={(e) => handleChange(index, 'endDate', e)}
                            inputWrapperStyle='endDateWrapper'
                            placeholderTxt='Enter Internship End Date'
                            isRequired={false}
                            errMsg={internShipErrors[index]?.endDate}
                        />
                        <div className='currentWorkDiv' style={{ display: (data.internships?.filter(v => v?.currentlyWorking).length > 0 && !exp?.currentlyWorking) ? "none" : '' }}>
                            <input type="checkbox" name="" id={`internshipCurrentlyWorking${index}`}
                                checked={exp.currentlyWorking}
                                value={exp.currentlyWorking ? false : true}
                                onChange={(e) => handleChange(index, 'currentlyWorking', e.target.value)}
                            />
                            <label htmlFor={`internshipCurrentlyWorking${index}`}>Currently Working Here</label>
                        </div>
                        <ConstantScreen.TextArea
                            labelTxt="Responsibilities and Achievements"
                            inputId="description"
                            textareaClass="descriptionInput"
                            txtAreaStyle={{ borderRadius: '5px' }}
                            valueText={exp?.profileSummary}
                            onChange={(e) => handleChange(index, 'profileSummary', e.target.value)}
                            txtAreaWrapperStyle="descriptionWrapper"
                            errMsg={internShipErrors[index]?.profileSummary}
                            placeholderTxt="Enter Responsibilities and Achievements"
                            isRequired={false}
                        />
                    </div>
                </div>
            ))}
            <ConstantScreen.AddField
                addFieldWrapper='fieldButton'
                fieldTxt='Add Internship'
                onClick={handleAddExperience}
            />
            <ToastContainer autoClose={6000} />
            <Modal
                isOpen={errModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setErrModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
            >
                <div className='modalCloseDiv'><span onClick={() => setErrModal(false)}>X</span></div>
                <div className='modalLottieDiv'>
                    <Lottie className='lottieFile' animationData={Error} loop={true} />
                </div>
                <div className='modalMsg'>{modalMsg}</div>
                <button className='modalOkBtn' onClick={() => setErrModal(false)}>OK</button>
            </Modal>

            <Modal
                isOpen={successModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setSuccessModal(false)}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
            >
                <div className='modalCloseDiv'><span onClick={() => setSuccessModal(false)}>X</span></div>
                <div className='modalLottieDiv'>
                    <Lottie className='lottieFile' animationData={Success} loop={true} />
                </div>
                <div className='modalMsg'>{modalMsg}</div>
                <button className='modalOkBtn' onClick={() => setSuccessModal(false)}>OK</button>
            </Modal>
        </div>
    );
};

export default InterShipDetails;
