import './MobileNumberField.css'

const MobileNumberField = ({
    labelTxt,
    inputClass,
    inputValue,
    onChange,
    inputStyle,
    inputWrapperStyle,
    readOnly,
    errMsg,
    inputId,
    placeholderTxt,
    isRequired = false,
    cntryCodeClass,
    cntryCodeOptions,
    setCntryCodeOptions,
    selectedCntryCode,
    setSelectedCntryCode,
    data,
    setData,
    changeReferralCode = false
}) => {

    const handleSelectCntryCode = (e) => {
        setSelectedCntryCode(JSON.parse(e?.target?.value))
        if (!changeReferralCode) {
            setData({ ...data, countryCode: JSON.parse(e?.target?.value) })
        } else {

        }
    }

    return (
        <div className={inputWrapperStyle} style={{ display: "flex", flexDirection: "column" }}>
            {!!labelTxt && <label className='inputLable' htmlFor={inputId}>{labelTxt}{isRequired && <small style={{ color: "#BC0000" }}>*</small>}</label>}
            <div className='MnoSelectInputWrapper' /* style={{ padding: "12px 0 12px 0px" }} */>
                <select className='cntryCodeClass' onChange={(e) => handleSelectCntryCode(e)}>
                    {cntryCodeOptions?.map((i) => <option
                        key={JSON.stringify(i)}
                        value={JSON.stringify(i)}
                        selected={data?.countryCode?.countryCodeId === i?.countryCodeId}
                    >
                        +{i?.countryCode}
                    </option>)}
                </select>
                <input id={inputId} type='text' maxLength={10} readOnly={readOnly} placeholder={placeholderTxt} style={inputStyle} value={inputValue} onChange={onChange} />
            </div>
            {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
        </div>
    )
}

export default MobileNumberField