import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { useSelectedCardContext } from '../../Context/Context'
import jobPreferenceIcon from "../../assets/jobPreference.svg"
import { useLocation, useNavigate } from 'react-router-dom'
import latestJobIcon from '../../assets/latestJobIcon.svg'
import { getAPICall, postAPICall } from '../../APIMethods/APIMethods'
import searchIcon from '../../assets/searchIcon.svg'
import React, { useEffect, useState } from 'react'
import { Url } from '../../EnvoirnmentSetup/APIs'
import Modal from "react-modal";
import './Dashboard.css'
import Lottie from 'lottie-react'

const Dashboard = () => {
    const navigate = useNavigate()
    const locationData = useLocation()
    const { selectedCard, setSelectedCard, setIsLoading } = useSelectedCardContext()
    const screenName = locationData?.state?.screen ?? 'Campus'

    const [jobList, setJobList] = useState([])
    const [jobList2, setJobList2] = useState([])
    const [searchVal, setSearchVal] = useState('')
    const [selectType, setSelectType] = useState(1)
    const [redirectModal,setRedirectModal]=React.useState({
        isOpen:false,
        link:''
    })

    useEffect(() => {
        let url = ''
        if (screenName == 'applied') {
            setSelectedCard(3)
            url = Url.GetAllInterestedJob
        } else if (screenName == 'suggested') {
            setSelectedCard(2)
            url = Url.GetAllFilterdJob
        } else if (screenName == 'QuikHire') {
            setSelectedCard(6)
            url = Url.GetQuikHireJobs
        } else {
            setSelectedCard(1)
            url = Url.getAllJobs
        }

        setIsLoading(true)
        getAPICall(url)
            .then((res) => {
                setIsLoading(false)
                let d = res?.DATA?.map((i) => {
                    if (screenName == 'applied') {
                        let stateStr = i?.job?.state?.map((s, ind) => s.stateName).join(', ') ?? ''
                        let skillStr = i?.job?.skill?.map((k, index) => k?.name).join(', ') ?? ''
                        return { ...i, job: { ...i.job, stateString: stateStr, skillString: skillStr } }
                    } else {
                        let stateStr = i?.state?.map((s, ind) => s.stateName).join(', ') ?? ''
                        let skillStr = i?.skill?.map((k, index) => k?.name).join(', ') ?? ''
                        return { ...i, stateString: stateStr, skillString: skillStr }
                    }
                }) ?? []
                document.getElementsByClassName('sortSelect')[0].selectedIndex = 0;
                setJobList(d)
                setJobList2(d)
            })

    }, [locationData?.state])

    useEffect(() => {
        try {
            if (jobList2?.length > 0) {
                // let newValue = [...jobList2.filter((v) => JSON.stringify(v)?.toLowerCase()?.indexOf(searchVal?.toLowerCase()) > -1)]
                // setJobList([...newValue])
                let filteredJobs = screenName != 'QuikHire' ? jobList2 : jobList2.filter((item) => {
                    if (selectType == 2) {
                        return item.experience.experience == 'Intern' ? item : null
                    } else if (selectType == 3) {
                        return item.experience.experience == 'Fresher' ? item : null
                    } else {
                        return item
                    }
                })
                setJobList(filteredJobs?.filter((item) => {
                    if (screenName == 'applied') {
                        return (
                            (item?.job?.designation[0]?.designationName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.job?.companyName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.job?.stateString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.job?.skillString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.job?.experience.experience?.toLowerCase())?.includes(searchVal?.toLowerCase())
                        )
                    } else {
                        return (
                            (item?.designation[0]?.designationName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.companyName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.stateString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.skillString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
                            (item?.experience.experience?.toLowerCase())?.includes(searchVal?.toLowerCase())
                        )
                    }
                }))
            }
        } catch (e) {
            console.log(e);
        }
    }, [searchVal, locationData?.state])

    const handleSortChange = (e) => {
        const selectedValue = e.target.value;
        // Implement sorting logic based on selectedValue
        let sortedList = [...jobList]; // Create a copy of jobList to avoid mutating state directly
        if (selectedValue === '1') {
            // Sort by Posted Date
            sortedList?.sort((a, b) => new Date(b.postedDate ?? b.job.postedDate) - new Date(a.postedDate ?? a.job.postedDate));
        } else if (selectedValue === '2') {
            // Sort by Active Date
            sortedList?.sort((a, b) => new Date(a.tillDate ?? a.job.tillDate) - new Date(b.tillDate ?? b.job.tillDate));
        } else /* if (selectedValue === '3')  */ {
            // Sort by Experience
            sortedList?.sort((a, b) => (a?.experience?.experienceId ?? a?.job?.experience?.experienceId) - (b?.experience?.experienceId ?? b?.job?.experience?.experienceId));
        }
        // Update state with sorted list
        setJobList(sortedList);
    };

    const QuikHireSortJobs = (value) => {
        let sortedList = [...jobList2];
        if (value === 2) {
            // Sort by Active Date
            sortedList = sortedList.filter((item) => item.experience.experience == 'Intern' ? item : null)
        } else if (value === 3) {
            // Sort by Experience
            sortedList = sortedList.filter((item) => item.experience.experience == 'Fresher' ? item : null)
        }
        // Update state with sorted list
        setJobList(sortedList);
        setSelectType(value)
        setSearchVal('')
    }

    const QuikHireJobStatus = (jobId) => {
        const userData = JSON.parse(sessionStorage.getItem('user'))
        const body = {
            "job": {
                "jobId": jobId
            },
            "viewed": true,
            "user": {
                "mobileNumber": userData.mobileNumber,
                "countryCode": {
                    "countryCodeId": userData?.countryCode?.countryCodeId ?? '249'
                }
            }
        }
        postAPICall(Url.QuikHireJobsStatus, body)
    }

    return (
        <div className='dashboardMainDiv'>
            <div className='headingRowDashboardDiv'>
                <div className='latestJobMainDiv'>
                    <div className='latestJobIconDiv'>
                        <img className='latestJobIcon' src={latestJobIcon} alt="" />
                    </div>
                    <div className='latestJobTextDiv'>
                        <span>{screenName} Jobs</span>
                    </div>
                </div>
                <div className='searchAndSortDiv'>
                    <div className='search'>
                        <img className='searchIcon' src={searchIcon} alt='' />
                        <input className='latestJobSearch' type="text" placeholder='Search Jobs' value={searchVal} onChange={(e) => setSearchVal(e?.target?.value)} />
                    </div>
                    <div className='sort'>
                        <select className="sortSelect" name="" id="" onChange={(e) => { handleSortChange(e) }}>
                            {/* <option value="">Select</option> */}
                            <option value="1">Sort by Posted Date</option>
                            <option value="2">Sort by Active Date</option>
                            <option value="3">Sort by Experience</option>
                        </select>
                    </div>
                </div>
            </div>
            {screenName == 'QuikHire' && <div className='jobToggleButtons'>
                <button onClick={() => QuikHireSortJobs(1)} className={selectType == 1 ? 'active' : ''}>All Jobs</button>
                <button onClick={() => QuikHireSortJobs(2)} className={selectType == 2 ? 'active' : ''}>Internships</button>
                <button onClick={() => QuikHireSortJobs(3)} className={selectType == 3 ? 'active' : ''}>Fresher Jobs</button>
            </div>}
            <div className='jobCards'>
                {jobList?.length > 0 ? <>
                    {jobList?.map((item) => (
                        <div className='latestSeperateJobCard' key={item?.jobId} onClick={() => {
                            // console.log(screenName == 'applied' ? item?.job?.jobId : item?.jobId);
                            if (screenName == 'QuikHire') {
                                QuikHireJobStatus(item?.jobId)
                                setRedirectModal({isOpen:true,link:`https://quikhire.ai/details.php?jobId=${btoa(item?.jobId)}`})
                                // window.open(`https://quikhire.ai/details.php?jobId=${btoa(item?.jobId)}`, '_blank');
                            } else {
                                navigate('/Main/jobDetails', { state: { jobId: screenName == 'applied' ? item?.job?.jobId : item?.jobId, headerHide: true, screen: screenName } })
                            }
                        }}>
                            <ComponentAndScreenConstant.jobCard
                                jobDetails={screenName == 'applied' ? item?.job : item}
                                applied={screenName == 'applied'}
                                applicationStatus={screenName == 'applied' ? item : undefined}
                            />
                        </div>
                    ))}</> : <div className='notFound'>
                    <img src={require('../../assets/NotFound.png')} style={{ height: '30vh', width: '30vh', }} />
                    No Data Found.
                </div>}
            </div>
            <ComponentAndScreenConstant.CommonModal
                isOpen={redirectModal.isOpen}
            // onRequestClose={() => { setRedirectModal(false) }}
            >
            <div className='redirectModal'>
                <button onClick={() => { setRedirectModal({isOpen:false}) }} className='closeBtn'>x</button>
                {/* <Lottie
                    loop={true}
                    animationData={require('../../assets/Opps.json')}
                    className="delAccModalErrLottie"
                /> */}
                <p className='redirectTitle'>Note</p>
                <p className='redirectText'>You are being redirected to a different platform.</p>
                <div className='redirectBtnDiv'>
                    <button 
                    onClick={() => { 
                        setRedirectModal({isOpen:false})
                        window.open(redirectModal?.link, '_blank');
                 }}>Proceed</button>
                    <button style={{ background: '#ccc' }} onClick={() => { setRedirectModal({isOpen:false}) }} >Cancel</button>
                </div>
            </div>
            </ComponentAndScreenConstant.CommonModal>
        </div>
    )
}

export default Dashboard