import './Mobile.css';
import Modal from 'react-modal';
import Lottie from 'lottie-react';
import 'react-toastify/dist/ReactToastify.css';
import { IoCopyOutline } from "react-icons/io5";
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { PDFExport } from "@progress/kendo-react-pdf";
import { IoArrowBackCircle } from "react-icons/io5";

import { ConstantScreen } from '../ResumeScreensAndComponents/ConstantScreen'

import { getQRCodeModalObject, initialModalState } from '../ResumeScreensAndComponents//ModalUtilies/ModalUtilies';
import { ApiCall, getAPICall, postAPICall, putAPICall } from '../../../APIMethods/APIMethods';
import { handleCopyDetails } from '../../../constants/constantFunctions';
import { ResumeApi, ResumeWebURL } from '../../../EnvoirnmentSetup/APIs';

import Error from '../../../assets/Opps.json'
import Success from '../../../assets/Success.json'
import QRCodeIcon from '../../../assets/QR code.svg'
import DownloadIcon from '../../../assets/Download.svg'
import logOutLogo from '../../../assets/logOutLogo.svg'
import Attachment from '../../../assets/attachment.svg'

const Mobile = () => {
  const [lgOutPopup, setLgOutPopup] = useState(false)
  const container = React.useRef(null);
  const divRef = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  const [notChangeUserUid, setNotChangeUserUid] = useState('')
  const [userUIDError, setUserUIDError] = useState(false)
  const [profileMsg, setProfileMsg] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [errModal, setErrModal] = useState(false)
  const [base64Img, setBase64Img] = useState('')
  const [modalMsg, setModalMsg] = useState("")
  const [modalMsgSuccess, setModalMsgSuccess] = useState("")
  const [navigationStatus, setNavigationStatus] = useState(1);
  const [errors, setErrors] = useState({});
  const [audioFile, setAudioFile] = useState('')
  const [certificationErrors, setCertificationErrors] = useState([]);
  const [designationSelectedIntern, setDesignationSelectedIntern] = useState({});
  const [internShipErrors, setInternShipErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [userUid, setUserUid] = useState(null);
  const [loader, setLoader] = useState(false);
  const [workExpErrors, setWorkExpErrors] = useState([]);
  const [successModal, setsuccessModal] = useState(false)
  const [educationErrors, setEducationErrors] = useState([]);
  const [closingErrors, setClosingErrors] = useState([]);
  const [allResumeDetails, setAllResumeDetails] = useState({});
  const [skillSelectedValues, setSkillSelectedValues] = useState([]);
  const [citySelectedValue, setCitySelectedValue] = useState({})
  const [languageSelectedValues, setLanguageSelectedValues] = useState([]);
  const [designationSelected, setDesignationSelected] = useState({});
  const [sucessModal, setSucessModal] = useState(initialModalState);
  const [UserResumeDetails, setUserResumeDetails] = useState({});
  const propData = useLocation()
  const navigate = useNavigate()
  const [getURLSections, setGetURLSections] = useState({
    userID: '',
    userAction: ''
  })
  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    middleName: null,
    lastName: null,
    mobileNumber: "",
    email: "",
    linkedInProfile: null,
    address: null,
    state: "",
    experienceStatus: false,
    isIntern: true,
    expWithIntern: false,
    totalExperience: "",
    city: {
      cityId: '',
      cityName: '',
      state: {
        stateId: null,
        stateName: ""
      }
    },
    zipcode: null,
    skills: [],
    countryCode: {},
    profileUrl: null,
    gender: null,
    summaryAudio: null
  });
  const [workExperience, setWorkExperience] = useState({
    experiences: [
      {
        companyName: null,
        location: null,
        startDate: null,
        endDate: null,
        isIntern: false,
        currentlyWorking: false,
        profileSummary: null,
        designation: {
          designationName: null
        }
      }
    ]
  });
  const [educationalDetails, setEducationalDetails] = useState({
    educations: [{
      degree: null,
      university: null,
      startYear: null,
      endYear: null
    }]
  });
  const [skillForSummary, setSkillForSummary] = useState('')
  const [certificationDetails, setCertificationDetails] = useState({
    certifications: []
  });
  const [internShipDetails, setInternShipDetails] = useState({
    internships: []
  });
  const [closingDetails, setClosingDetails] = useState({
    languages: [
      {
        "languageId": 1,
        "languageName": "English"
      },
      {
        "languageId": 26,
        "languageName": "Hindi"
      },
    ],
    summary: '',
    referrals: [],
    hireMeQuestion: ''
  });


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // minWidth:"30%",
      width: "80%",
      borderRadius: "10px",
      padding: "10px"
    },
  };

  const customStyles1 = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: "95%",
      maxWidth: "95%",
      borderRadius: "10px",
      padding: "10px"
    },
  };
  useEffect(() => {
    if (getURLSections.userAction != "resumeEdit") {
      const firstName = (personalDetails?.firstName || '').replace(/\s+/g, '').toLowerCase().substring(0, 15);
      const mobileNumber = (personalDetails?.mobileNumber || '').toString().substring(Math.max(0, (personalDetails?.mobileNumber || '').toString().length - 4));
      setUserUid(firstName + mobileNumber)
    }
  }, [personalDetails])

  useEffect(() => {
    if (propData?.state?.userData) {
      let userData = propData.state.userData
      setPersonalDetails((prev) => ({
        ...prev,
        firstName: userData?.fullName || '',
        mobileNumber: userData?.mobileNumber || '',
        email: userData?.userName || '',
      }))
    }
  }, [])

  useEffect(() => {
    const mergedData = {
      ...personalDetails,
      ...workExperience,
      ...internShipDetails,
      ...certificationDetails,
      ...educationalDetails,
      ...closingDetails,
      userUid
    };
    setAllResumeDetails(mergedData);

  }, [workExperience, certificationDetails, internShipDetails, educationalDetails, personalDetails, closingDetails, userUid]);
  useEffect(() => {
    const updatedSkills = personalDetails?.skills?.map((item) => ({
      value: item.skillId,
      label: item.name,
    }));
    setSkillSelectedValues(updatedSkills);
    if (personalDetails?.city?.cityId != "") {
      const updatedCity = {
        value: personalDetails?.city?.cityId,
        label: personalDetails?.city?.cityName,
        state: personalDetails?.state,
      }
      setCitySelectedValue(updatedCity)
    } else {
      setCitySelectedValue('')
    }
    const hasNullDesignation = workExperience?.experiences?.every(
      item => !item.designation || !item.designation.designationName
    );
    if (!hasNullDesignation) {
      const updatedDesignation = workExperience?.experiences?.map((item) => ({
        value: item.designation?.designationId ? item.designation.designationId : item?.designation?.designationName,
        label: item?.designation?.designationName
      }));
      setDesignationSelected(updatedDesignation);
    } else {
      setDesignationSelected('')
    }
    // for internSHipDetails
    const hasNullDesignationIntern = internShipDetails?.internships?.every(
      item => !item.designation || !item.designation.designationName
    );
    if (!hasNullDesignationIntern) {
      const updatedDesignationIntern = internShipDetails?.internships?.map((item) => ({
        value: item.designation?.designationId,
        label: item?.designation?.designationName
      }));
      setDesignationSelectedIntern(updatedDesignationIntern);
    } else {
      setDesignationSelectedIntern('')
    }



    const updatedLanguages = closingDetails?.languages?.map((item) => ({
      value: item.languageId,
      label: item.languageName,
    }));

    setLanguageSelectedValues(updatedLanguages);
    const updateSkillsDetails = personalDetails.skills?.map((item) => {
      return item.name;
    });

    const firstFiveSkills = updateSkillsDetails?.slice(0, 5);

    let concatenatedSkills;
    if (firstFiveSkills?.length === 1) {
      concatenatedSkills = firstFiveSkills?.[0];
    } else {
      const lastElement = firstFiveSkills?.pop();
      concatenatedSkills = firstFiveSkills?.length > 0 ? `${firstFiveSkills.join(', ')} and ${lastElement}` : lastElement;
    }
    setSkillForSummary(concatenatedSkills)

  }, [personalDetails, workExperience, closingDetails, internShipDetails]);


  useEffect(() => {
    const firstEducation = educationalDetails?.educations?.[0] || {
      degree: null,
      university: null,
      startYear: null,
      endYear: null
    };
    const currentExperience = workExperience?.experiences.find(exp => exp.currentlyWorking);

    let nearestLastExperience = currentExperience;

    if (!nearestLastExperience) {
      nearestLastExperience = workExperience.experiences
        .filter(exp => !exp.currentlyWorking)
        .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))?.[0];
    }
    const keyStrengths = "improve efficiency, drive revenue, enhance customer satisfaction"; // You can customize this string as needed
    const specificSkills = "project management, team leadership, and strategic planning"; // You can customize this string as needed
    const getCurrentExperienceSummary = (
      nearestLastExperience,
      personalDetails,
      skillForSummary,
      keyStrengths,
      specificSkills
    ) => {
      if (nearestLastExperience?.designation?.designationName && personalDetails?.totalExperience && skillForSummary?.length !== 0) {
        const totalExperience = /year(s)?/i.test(personalDetails?.totalExperience) ? personalDetails.totalExperience : `${personalDetails.totalExperience} Years`;
        return `Highly skilled and results-oriented ${nearestLastExperience.designation.designationName} with ${totalExperience} of experience. Demonstrated expertise in ${skillForSummary}, with a proven ability to ${keyStrengths}. Adept at ${specificSkills}, with a strong track record of success. Committed to delivering high-quality results and fostering collaborative team environments.`;
      } else {
        return ''
      }
    };

    const getRecentGraduateSummary = (firstEducation, skillForSummary) => {
      if (firstEducation.degree && firstEducation.university && skillForSummary) {
        return `Recent graduate with a ${firstEducation.degree} from ${firstEducation.university}. Possess strong foundational knowledge in ${skillForSummary}, complemented by hands-on experience through internships and academic projects. Demonstrates excellent problem-solving abilities, effective communication skills, and a strong commitment to learning and professional growth. Eager to contribute to a dynamic team and apply my skills to achieve organizational goals.`;
      }
      return '';
    };

    if (personalDetails?.experienceStatus) {
      setClosingDetails((prevDetails) => ({
        ...prevDetails,
        summary: getCurrentExperienceSummary(nearestLastExperience, personalDetails, skillForSummary, keyStrengths, specificSkills),
      }));
    } else {
      setClosingDetails((prevDetails) => ({
        ...prevDetails,
        summary: getRecentGraduateSummary(firstEducation, skillForSummary),
      }));
    }
  }, [educationalDetails, skillForSummary, personalDetails, workExperience]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const message = 'Are you sure you want to leave? Your changes may not be saved.';
      e.preventDefault();
      e.returnValue = message;
      return message;

    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const handleNavigateDashoard = () => {
    navigate("/Dashboard")
  }

  const handleuserIDChange = (e) => {
    const regex = /^[a-zA-Z0-9]+$/;
    setUserUid(e.target.value)
    if (regex.test(e.target.value)) {
      setUserUIDError(false)
    } else {
      setUserUIDError(true)
      setProfileMsg("Do not use spaces, symbols, or special characters.")
    }
  }
  const OpenQRShowModel = (generatedLinks) => {
    let generatedLink = generatedLinks != undefined ? generatedLinks : ""
    const obj = getQRCodeModalObject(`${generatedLink}`, false,)
    setSucessModal(obj)
  }

  const CloseSuccesModel = () => {
    setSucessModal(() => {
      return { ...sucessModal, isRequired: false, successMsg: false, wrongMsg: false, deleteMsg: false, okBtnHide: false };
    })
  }

  const validatePersonalDetails = () => {
    const errorsPersonal = {};
    const nameRegex = /^[A-Za-z][A-Za-z\s]*$/
    if (!personalDetails.firstName || personalDetails.firstName?.trim() === '') {
      errorsPersonal.firstName = 'Full Name is required.';
    } else if (!nameRegex.test(personalDetails.firstName)) {
      errorsPersonal.firstName = 'Full Name must start with a letter and contain only letters and spaces.';
    }
    const mobileNumberRegex = /^\d{10}$/;
    if (!personalDetails.mobileNumber || personalDetails.mobileNumber?.trim() === '') {
      errorsPersonal.mobileNumber = 'Mobile Number is required.';
    } else if (!mobileNumberRegex.test(personalDetails.mobileNumber)) {
      errorsPersonal.mobileNumber = 'Mobile Number must be a 10-digit number.';
    } else if (!!errors.mobileNumber) {
      errorsPersonal.mobileNumber = 'This mobile number is already registered.'
    }
    const emailRegex = /^(?!.*\.\.)(?=[a-zA-Z]+[a-zA-Z0-9._%+-]*@)[a-zA-Z0-9._%+-]+@[a-zA-Z.-]{1,5}[a-zA-Z][a-zA-Z.-]*\.[a-zA-Z]{2,}$/;
    if (!personalDetails.email || personalDetails.email?.trim() === '') {
      errorsPersonal.email = 'Email is required.';
    } else if (!emailRegex.test(personalDetails.email)) {
      errorsPersonal.email = 'Invalid email address.';
    }
    // if (personalDetails.experienceStatus && (!personalDetails.totalExperience || personalDetails.totalExperience?.trim() === '')) {
    //   errorsPersonal.totalExperience = 'Total Experience is required.';
    // }
    const sanitizedExp = personalDetails.totalExperience?.replace(/[^\d.]/g, '')?.trim();
    const validExpPattern = /^(?!0\.0*$|0\.03*$)(?:[1-9]\d{0,1}|0)(\.\d{1,2})?$/;
    if (personalDetails.experienceStatus && (!personalDetails.totalExperience || personalDetails.totalExperience?.trim() === '')) {
      errorsPersonal.totalExperience = 'Total Experience is required.';
    } else if (personalDetails.totalExperience?.trim() !== '' && !sanitizedExp?.match(validExpPattern)) {
      errorsPersonal.totalExperience = 'Total Experience is invalid.';
    }
    if (!personalDetails.city.cityId || personalDetails.city.cityName?.trim() === '') {
      errorsPersonal.city = 'City is required.';
    }
    if (personalDetails.skills?.length === 0) {
      errorsPersonal.skill = 'At least one skill is required.';
    }
    if (!(!!personalDetails?.gender)) {
      errorsPersonal.gender = 'Please select gender.'
    }
    return errorsPersonal;
  };

  const validateClosingDetailsDetails = () => {
    const errors = {};
    const startsWithLetterRegex = /^[^\s][\s\S]*$/;
    const emailRegex = /^(?!.*\.\.)(?=[a-zA-Z]+[a-zA-Z0-9._%+-]*@)[a-zA-Z0-9._%+-]+@[a-zA-Z.-]{1,5}[a-zA-Z][a-zA-Z.-]*\.[a-zA-Z]{2,}$/;
    const mobileNumberRegex = /^[0-9]{10}$/;

    if (!closingDetails.summary || closingDetails.summary?.trim() === '') {
      errors.summary = 'Professional Summary is required and must start with a letter.';
    } else if (!startsWithLetterRegex.test(closingDetails.summary)) {
      errors.summary = 'Professional Summary must start with a letter.';
    }

    if (!closingDetails.hireMeQuestion || closingDetails.hireMeQuestion?.trim() === '') {
      errors.hireMeQuestion = 'Why Should You Hire Me? is required and must start with a letter.';
    } else if (!startsWithLetterRegex.test(closingDetails.hireMeQuestion)) {
      errors.hireMeQuestion = 'Why Should You Hire Me? must start with a letter.';
    }

    if (!closingDetails.languages || closingDetails.languages.length === 0) {
      errors.languages = 'At least one Language is required.';
    }


    const referalErrorDetails = closingDetails?.referrals?.map((referral, index) => {
      const referralErrors = {};
      if (referral.referrerName && !referral.mobileNumber && !referral.email) {
        referralErrors.referrerName = 'Mobile Number or Email is required if Referrer Name is provided.';
      } else if ((referral.mobileNumber || referral.email) && !referral.referrerName) {
        referralErrors.referrerName = 'Referrer Name is required if Mobile Number or Email is provided.';
      } else if ((referral.mobileNumber && referral.email) && !referral.referrerName) {
        referralErrors.referrerName = 'Referrer Name is required if Mobile Number and Email is provided.';
      } else if (referral.referrerName && !startsWithLetterRegex.test(referral.referrerName)) {
        referralErrors.referrerName = 'Referrer Name must start with a letter.';
      }

      if (referral.mobileNumber && !mobileNumberRegex.test(referral.mobileNumber)) {
        referralErrors.mobileNumber = 'Mobile Number must be a 10-digit number.';
      }

      if (referral.email && !emailRegex.test(referral.email)) {
        referralErrors.email = 'Invalid email format.';
      }
      return referralErrors;
    });
    // Only assign errors.referrals if there are any errors in referalErrorDetails
    if (referalErrorDetails.some(errors => Object.keys(errors).length > 0)) {
      errors.referrals = referalErrorDetails;
    }

    return errors;
  };

  const validateCertificationDetails = () => {
    const errors = {};
    const startsWithLetterRegex = /^[A-Za-z][A-Za-z\s\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/;

    const certificationErrorDetails = certificationDetails.certifications.map((certification, index) => {
      const certificationErrors = {};
      if (certification.certificateName && !certification.instituteName && !certification.endDate) {
        certificationErrors.certificateName = 'Institute Name or End Date is required if Certificate Name is provided.';
      } else if ((certification.instituteName || certification.endDate) && !certification.certificateName) {
        certificationErrors.certificateName = 'Certificate Name is required if Institute Name or End Date is provided.';
      } else if ((certification.instituteName && certification.endDate) && !certification.certificateName) {
        certificationErrors.certificateName = 'Certificate Name is required if Institute Name and End Date is provided.';
      } else if (certification.certificateName && !startsWithLetterRegex.test(certification.certificateName)) {
        certificationErrors.certificateName = 'Certificate Name must start with a letter.';
      }

      if (certification.instituteName && !startsWithLetterRegex.test(certification.instituteName)) {
        certificationErrors.instituteName = 'Institute Name must start with a letter';
      }
      return certificationErrors;
    });
    // Only assign errors.certifications if there are any errors in referalErrorDetails
    if (certificationErrorDetails.some(errors => Object.keys(errors).length > 0)) {
      errors.certifications = certificationErrorDetails;
    }

    return errors;
  };
  const validateInternshipDetails = () => {
    const errors = {};
    internShipDetails.internships.forEach((internship, index) => {
      const allFields = [
        internship.companyName,
        // internship.location,
        internship.startDate,
        internship.endDate,
        internship.profileSummary,
        internship.designation.designationName
      ];

      const anyFieldFilled = allFields.some(field => !!field);

      if (anyFieldFilled) {
        const allFieldsFilled = allFields?.every(field => !!field);

        if (!allFieldsFilled) {
          errors[index] = {};

          if (!internship.companyName) errors[index].companyName = 'Company/Institute Name cannot be empty.';
          if (!internship.startDate) errors[index].startDate = 'Internship Start date is required';
          if (!internship.endDate && !internship.currentlyWorking) errors[index].endDate = 'Internship End date is required';
          if (!internship.profileSummary) errors[index].profileSummary = 'Responsibilities and Achievements cannot be empty.';
          if (!internship.designation.designationName) errors[index].designationName = 'Internship Title Name cannot be empty.';

          // Remove empty error objects if no errors were added
          if (Object.keys(errors[index]).length === 0) {
            delete errors[index];
          }
        }
      }
    });

    return errors;
  };


  const validateWorkExperience = () => {
    const errors = [];
    workExperience.experiences.forEach((experience, index) => {
      const experienceErrors = {};
      if (!experience.companyName || experience.companyName?.trim() === '') {
        experienceErrors.companyName = 'Company Name is required.';
      }
      if (!experience.startDate || experience.startDate?.trim() === '') {
        experienceErrors.startDate = 'Start Date is required.';
      }
      if ((!experience.endDate || experience.endDate?.trim() === '') && !experience.currentlyWorking) {
        experienceErrors.endDate = 'End Date is required.';
      }
      if (!experience.profileSummary || experience.profileSummary?.trim() === '') {
        experienceErrors.profileSummary = 'Responsibilities is required.';
      }
      if (!experience.designation.designationName || experience.designation.designationName?.trim() === '') {
        experienceErrors.designation = 'Designation Name is required.';
      }
      if (Object.keys(experienceErrors).length > 0) {
        errors[index] = experienceErrors;
      }
    });

    return errors;
  };
  const validateEducationalDetails = () => {
    const errors = [];
    const startsWithLetterRegex = /^[A-Za-z][A-Za-z\s.&()]*$/;
    educationalDetails.educations.forEach((education, index) => {
      const educationErrors = {};
      if (!education.degree || education.degree?.trim() === '') {
        educationErrors.degree = 'Degree Name is required.';
      } else if (!startsWithLetterRegex.test(education.degree)) {
        educationErrors.degree = 'Degree Name must start with a letter and contain only letters and spaces..';
      }
      if (!education.university || education.university?.trim() === '') {
        educationErrors.university = 'College Name is required.';
      } else if (!startsWithLetterRegex.test(education.university)) {
        educationErrors.university = 'College Name must start with a letter and contain only letters and spaces..';
      }
      if (!education.startYear || education.startYear?.trim() === '') {
        educationErrors.startYear = 'Start Year is required.';
      }
      if (!education.endYear || education.endYear?.trim() === '') {
        educationErrors.endYear = 'End Year is required.';
      }
      if (Object.keys(educationErrors).length > 0) {
        errors[index] = educationErrors;
      }
    });

    return errors;
  };

  const handleNextDetails = () => {
    divRef.current.scrollTop = 0;
    const validationPersonalErrors = validatePersonalDetails();
    const validationExpErrors = validateWorkExperience();
    const validationEducationErrors = validateEducationalDetails();
    const validationClosingDetails = validateClosingDetailsDetails();
    const validateCertification = validateCertificationDetails()
    const internShipErrors = validateInternshipDetails();
    if (navigationStatus == 1) {
      if (Object.keys(validationPersonalErrors).length === 0) {
        setNavigationStatus((prevStatus) => (prevStatus < 7 ? prevStatus + 1 : prevStatus));
      } else {
        setErrors(validationPersonalErrors);
      }
    } else if (navigationStatus == 2) {
      if (Object.keys(internShipErrors).length > 0) {
        setInternShipErrors(internShipErrors)
      } else {
        if (personalDetails.experienceStatus) {
          setNavigationStatus(3);
        } else {
          setNavigationStatus(4);
        }
      }
    } else if (navigationStatus == 3) {
      if (validationExpErrors.length > 0) {
        setWorkExpErrors(validationExpErrors);
      } else {
        setWorkExpErrors({});
        setNavigationStatus((prevStatus) => (prevStatus < 7 ? prevStatus + 1 : prevStatus));
      }
    } else if (navigationStatus == 4) {
      if (validationEducationErrors.length > 0) {
        setEducationErrors(validationEducationErrors);
      } else {
        setNavigationStatus((prevStatus) => (prevStatus < 7 ? prevStatus + 1 : prevStatus));
      }
    } else if (navigationStatus == 5) {
      if (validateCertification.length > 0) {
        setCertificationErrors(validateCertification);
      } else {
        setCertificationErrors({})
        setNavigationStatus((prevStatus) => (prevStatus < 7 ? prevStatus + 1 : prevStatus));
      }
    } else if (navigationStatus === 6) {
      if (Object.keys(validationClosingDetails).length === 0) {
        setClosingErrors({});
        setNavigationStatus((prevStatus) => (prevStatus < 7 ? prevStatus + 1 : prevStatus));
      } else {
        setClosingErrors(validationClosingDetails);
      }
    } else {
      setNavigationStatus((prevStatus) => (prevStatus < 7 ? prevStatus + 1 : prevStatus));
    }
  };
  const handlePrevDetails = () => {
    if (navigationStatus == 4 && !personalDetails.experienceStatus) {
      setNavigationStatus((2));
    } else {
      setNavigationStatus((prevStatus) => (prevStatus > 1 ? prevStatus - 1 : prevStatus));
    }
  };

  const handleUploadProfileImage = () => {
    setConfirmationModal(false)
    setLoader(true)
    if (!!personalDetails?.profileUrl?.name) {
      const formData = new FormData();
      formData.append('profileImage', personalDetails?.profileUrl);
      ApiCall(ResumeApi.UploadDocumentToBucket.replace("{folderName}", "profile_images"), 'POST', true, 'file upload', formData, true)
        // DocumentUploader(personalDetails?.profileUrl, 'profileImage', "profile_images")
        .then((result) => {
          setLoader(false)
          if (result.SUCCESS) {
            handleUploadAudioFile(result?.DATA?.PROFILE_IMAGE)
          } else {
            handleUploadAudioFile()
          }
        })
    } else {
      setLoader(false)
      handleUploadAudioFile()
    }
  }

  const handleUploadAudioFile = (profileUrl = null) => {
    setLoader(true)
    if (!!personalDetails?.summaryAudio?.name) {
      const formData = new FormData();
      formData.append('summaryAudio', personalDetails?.summaryAudio);
      ApiCall(ResumeApi.UploadDocumentToBucket.replace("{folderName}", "summary_audio"), 'POST', true, 'file upload', formData, true)
        // DocumentUploader(personalDetails?.summaryAudio, 'summaryAudio', "summary_audio")
        .then((result) => {
          setLoader(false)
          if (result.SUCCESS) {
            handleSubmitUserData({ profileImageUrl: profileUrl, audioUrl: result?.DATA?.SUMMARY_AUDIO })
          } else {
            handleSubmitUserData({ profileImageUrl: profileUrl })
          }
        })
    }
    else {
      setLoader(false)
      handleSubmitUserData({ profileImageUrl: profileUrl })
    }
  }

  const handleSubmitUserData = async (photoAndAudioObj = null) => {
    setLoader(true)
    // if (!hasSubmitted || propData?.state?.editResume) {
    try {
      let body = UserResumeDetails ? { ...UserResumeDetails, ...allResumeDetails, totalExperience: personalDetails?.totalExperience ?? 0, } : allResumeDetails
      body = { ...body, profileUrl: photoAndAudioObj?.profileImageUrl ?? personalDetails?.profileUrl, summaryAudio: photoAndAudioObj?.audioUrl ?? personalDetails?.summaryAudio }
      const res = Object.keys(UserResumeDetails).length != 0 ? await putAPICall(ResumeApi.editResumeDetails, body) : await postAPICall(ResumeApi.submitResumeDetails, body);
      if (res.SUCCESS) {
        // setUserUid(res.DATA.userUid);
        setHasSubmitted(true);
        setLoader(false)

        setModalMsgSuccess(`Resume ${propData?.state?.editResume ? "Updated" : "Created"} Successfully.`)
        setsuccessModal(true)
        // return res.DATA.userId;
      } else if (res?.SUCCESS == false) {
        setLoader(false)
        setHasSubmitted(false);
        setModalMsg(res?.message ?? '')
        setErrModal(true)
      }
    } catch (error) {
      setLoader(false)
      console.error('Error submitting resume details:', error);
    }
  };
  const customStylesModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };

  // https://www.shareresume.co/#/Main/zMpyMt4bwUKCVo6abgzx93rJ0RwCixDp3HKxIN919uA=
  const handleAfterSubmitActions = async (callType) => {
    if (callType === 'link' && userUid) {
      const encryptedStringNavigation = `${userUid}`;
      const resumeLink = ResumeWebURL + encryptedStringNavigation;
      await copyToClipboard(resumeLink);
    } else if (callType === 'generateQr' && userUid) {
      const encryptedStringNavigation = `${userUid}`;
      const resumeLink = ResumeWebURL + encryptedStringNavigation;
      OpenQRShowModel(resumeLink)
    } else if (callType === 'downlaodPdf' && userUid) {
      if (pdfExportComponent?.current) {
        pdfExportComponent?.current.save();
      }
    }
  }

  useEffect(() => {
    if (!allResumeDetails?.experienceStatus) {
      setWorkExperience({
        experiences: [{
          companyName: null, location: null, startDate: null, endDate: null, profileSummary: null,
          designation: {
            designationName: null
          }
        }]
      })
    }
  }, [allResumeDetails?.experienceStatus])
  const copyToClipboard = async (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.width = '1px';
    textarea.style.height = '1px';
    textarea.style.opacity = 0;
    textarea.setAttribute('readonly', '');
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.log(err)
    }
    try {
      handleCopyDetails(text)
      // document.execCommand('copy');
    } catch (err) {
      console.log(err)
    }
    // document.body.removeChild(textarea);
  };

  const showTooltip = (message) => {
    const tooltipShowDiv = document.querySelector('.actionButtonDiv');
    const tooltip = document.createElement('div');
    tooltip.textContent = message;
    tooltip.className = 'tooltip';
    tooltipShowDiv.appendChild(tooltip);
    setTimeout(() => {
      tooltipShowDiv.removeChild(tooltip);
    }, 4000);
  };

  useEffect(() => {
    const windowLocationArr = window.location.href;
    const splitArr = windowLocationArr.split('/Main/');
    const lastIndex = splitArr.length - 1;
    const encryptedData = splitArr[lastIndex];
    try {
      // const decryptedStringNavigation = handleDecrypt(encryptedData);
      const urlSplit = encryptedData.split('/');
      const userID = urlSplit?.[1];
      const userAction = urlSplit?.[0];
      setGetURLSections({
        ...getURLSections,
        ['userAction']: userAction
      })
      if (userAction === 'resumeView' || userAction === 'resumeEdit') {
        if (!sessionStorage.getItem("token") && userAction === 'resumeEdit') {
          handleLogout()
          // navigate('/login',{state:{login:true}})        
        } else {
          setLoader(true)
          getAPICall(ResumeApi.getResumeByUserID?.replace('{userUid}', userID))
            .then((res) => {
              const responseData = res.DATA;
              setTimeout(() => {
                const cityData = responseData.city || {};
                setUserResumeDetails(responseData)
                setBase64Img(responseData?.base64ImageUrl)
                setUserUid(responseData?.userUid)
                setNotChangeUserUid(responseData?.userUid)
                setPersonalDetails({
                  ...personalDetails,
                  ['firstName']: responseData.firstName || null,
                  ['middleName']: responseData.middleName || null,
                  ['lastName']: responseData.lastName || null,
                  ['mobileNumber']: responseData.mobileNumber || null,
                  ['email']: responseData.email || null,
                  ['linkedInProfile']: responseData.linkedInProfile || null,
                  ['address']: responseData.address || null,
                  ['state']: responseData.state || null,
                  ['totalExperience']: responseData.totalExperience,
                  ['city']: cityData.cityId ? { // Check if cityId exists
                    cityId: cityData.cityId,
                    cityName: cityData.cityName || null
                  } : null,
                  ['zipcode']: responseData.zipcode || null,
                  ['skills']: responseData.skills || null,
                  ['experienceStatus']: responseData.experienceStatus,
                  ['countryCode']: responseData?.countryCode,
                  ['isIntern']: responseData?.isIntern,
                  ['expWithIntern']: responseData?.expWithIntern,
                  ['profileUrl']: responseData?.profileUrl,
                  ['gender']: responseData?.gender,
                  ['summaryAudio']: responseData?.summaryAudio,
                  ['profileImageName']: responseData?.profileImageName,
                  ['summaryAudioName']: responseData?.summaryAudioName
                });
                setAudioFile(responseData?.summaryAudio)
                const updatedCity = {
                  value: cityData?.cityId,
                  label: cityData?.cityName,
                  state: cityData?.state,
                }
                setCitySelectedValue(updatedCity)

                setCertificationDetails(() => ({
                  ...certificationDetails,
                  certifications: res.DATA.certifications
                }));
                setEducationalDetails(() => ({
                  ...educationalDetails,
                  educations: res.DATA.educations
                }));
                setInternShipDetails(() => ({
                  ...internShipDetails,
                  internships: res.DATA.internships
                }));
                setWorkExperience(() => ({
                  ...workExperience,
                  experiences: res.DATA.experiences?.length > 0 ? res.DATA.experiences : [
                    {
                      companyName: null,
                      location: null,
                      startDate: null,
                      endDate: null,
                      isIntern: false,
                      profileSummary: null,
                      designation: {
                        designationName: null
                      }
                    }
                  ]
                }));
                setClosingDetails(() => ({
                  ...closingDetails,
                  ['languages']: res.DATA.languages,
                  ['summary']: res.DATA.summary,
                  ['referrals']: res.DATA?.referrals || null,
                  ['hireMeQuestion']: res?.DATA?.hireMeQuestion
                }));
                setLoader(false)
              }, 2000);
            })
            .catch((error) => {
              // console.error('API call error:', error);
            });
        }
      }
    } catch (error) {
      console.error('Decryption error:', error);
    }
  }, []);
  const submitWithoutURLChange = () => {
    if (getURLSections.userAction != "resumeEdit") {
      const firstName = (personalDetails?.firstName || '').replace(/\s+/g, '').toLowerCase().substring(0, 15);
      const mobileNumber = (personalDetails?.mobileNumber || '').toString().substring(Math.max(0, (personalDetails?.mobileNumber || '').toString().length - 4));
      setUserUid(firstName + mobileNumber)
      submitDetailsAfterChange()
    } else {
      setUserUid(notChangeUserUid)
      submitDetailsAfterChange()
    }
  }
  const submitDetailsAfterChange = () => {
    setTimeout(() => {
      console.log('>>>>>>>>>>>', allResumeDetails)
    }, 1000);
  }
  const ValidateprofileUrl = (profileUrl) => {
    getAPICall(ResumeApi.checkUserUid.replace("{uid}", profileUrl))
      .then((res) => {
        console.log('>>>>>>>>>>>', res)
        if (res?.DATA) {
          setUserUIDError(true)
          setProfileMsg("This Profile Id already Present")
        } else {
          console.log('>>>>>>>>>>>not present')
        }
      })
      .catch((e) => console.log(e));
    // }
  }
  const renderCurrentScreen = () => {
    switch (navigationStatus) {
      case 1:
        return <ConstantScreen.PersonalDetails getURLSections={getURLSections} audioFile={audioFile} setAudioFile={setAudioFile} citySelectedValue={citySelectedValue} setErrors={setErrors} errors={errors} setBase64Img={setBase64Img} skillSelectedValues={skillSelectedValues} data={personalDetails} setData={setPersonalDetails} setSubmitResume={setHasSubmitted} />;
      case 2:
        return <ConstantScreen.InterShipDetails setInternShipErrors={setInternShipErrors} internShipErrors={internShipErrors} designationSelectedIntern={designationSelectedIntern} data={internShipDetails} setData={setInternShipDetails} setSubmitResume={setHasSubmitted} />
      case 3:
        return <ConstantScreen.WorkExperience setWorkExpErrors={setWorkExpErrors} workExpErrors={workExpErrors} designationSelected={designationSelected} data={workExperience} setData={setWorkExperience} setSubmitResume={setHasSubmitted} />;
      case 4:
        return <ConstantScreen.EducationalDetails setEducationErrors={setEducationErrors} educationErrors={educationErrors} data={educationalDetails} setData={setEducationalDetails} setSubmitResume={setHasSubmitted} />;
      case 5:
        return <ConstantScreen.CertificationDetails certificationErrors={certificationErrors} setCertificationErrors={setCertificationErrors} data={certificationDetails} setData={setCertificationDetails} setSubmitResume={setHasSubmitted} />;
      case 6:
        return <ConstantScreen.ClosingDetails setClosingErrors={setClosingErrors} closingErrors={closingErrors} languageSelectedValues={languageSelectedValues} data={closingDetails} setData={setClosingDetails} setSubmitResume={setHasSubmitted} />;
      case 7:
        return <>
          <ConstantScreen.MobileResumePreview audioFile={audioFile} userAction={getURLSections.userAction} allResumeDetails={allResumeDetails} base64Img={base64Img} />
          {/* {!!allResumeDetails?.summaryAudio && <div>
              <div style={{display:"flex",margin:"10px auto",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"5px"}}>
              <h3 style={{fontWeight:"normal",fontSize:"1rem",margin:"0px"}}>Audio Preview:</h3>
              <audio controls>
               <source src={allResumeDetails?.summaryAudio?.name? URL.createObjectURL(allResumeDetails?.summaryAudio) : allResumeDetails?.summaryAudio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
            </div>
         } */}
        </>;
      default:
        return null;
    }
  };

  const renderFieldLabel = () => {
    switch (navigationStatus) {
      case 1:
        return 'Personal Details';
      case 2:
        return "Internship Details";
      case 3:
        return "Experience Details";
      case 4:
        return 'Educational Details';
      case 5:
        return 'Certification Details';
      case 6:
        return 'Profile Information';
      default:
        return '';
    }
  };
  const handleLogout = () => {
    sessionStorage.clear()
    navigate('/login', { state: { login: true } })
  }
  const handleLogoutPopUp = () => {
    setLgOutPopup(true)
  }

  return (
    <div className="MainBodyDiv">
      {getURLSections.userAction == 'resumeView' ? <ConstantScreen.ShowGeneratedResume base64Img={base64Img} audioFile={audioFile} allResumeDetails={allResumeDetails} /> : <div className='mainInnerEditableDiv'>
        <div className="mainHeaderDiv" style={{ position: 'relative' }}>
          <button
            title='to My Resume Details'
            style={{ color: '#ccc', position: 'absolute', left: '0px', top: '10px' }}
            onClick={() => { navigate('/Main/myResume') }}>
            <IoArrowBackCircle size={'2rem'} />
          </button>
          <span className={`mainHeadertext ${propData?.state?.editResume == "mainHeadertextEdit"}`}>{propData?.state?.editResume ? 'Edit resume' : navigationStatus == 6 ? "Preview" : "Create resume"}</span>
          {propData?.state?.editResume && <>
            {/* <button className='logOutBtn' name='submit' onClick={() => { handleLogoutPopUp() }} style={{ position: 'absolute', top: '5px', right: '10px' }}>Logout</button> */}
          </>}
          {/* {(!hasSubmitted && !propData?.state?.editResume) &&
            <button className='logOutBtn' name='submit' style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={() => handleLogout()}>
              {"Login"}
            </button>
          } */}
        </div>
        <div className="formLabel" style={{ textAlign: 'left' }}>
          <span className={`${navigationStatus == 2 || navigationStatus == 3 ? "fieldLablewithHint" : ""} fieldLabel`}>{renderFieldLabel()}</span>
          {navigationStatus == 2 ? <small className='hintMessageForEduDetailsMob'>Start with your most recent experience at the top.</small> : navigationStatus == 3 ? <small className='hintMessageForEduDetailsMob'>Start with your most recent education at the top.</small> : ""}
        </div>
        <div ref={divRef} className={`${navigationStatus == 6 ? "resumePreviewMainDiv" : "resumeFieldsMainDiv"}`}>
          {renderCurrentScreen()}
        </div>
        <div className="actionButtonDiv">
          <div className="buttonInnerDiv">
            {navigationStatus < 6 && (
              <button type="button" className="nextButton" onClick={handleNextDetails}>
                Next
              </button>)}
            {propData?.state?.dashboardNavigate && navigationStatus < 2 && <button className='nextButton' name='submit' onClick={handleNavigateDashoard}>Back</button>}

            {navigationStatus == 6 && (
              <button type="button" className="nextButton" onClick={() => { handleNextDetails() }}>
                Preview Resume
              </button>)}
            {navigationStatus > 6 && (
              <>
                {!hasSubmitted ? <button type='button' style={{ backgroundColor: "#007749", color: "#FFFF" }} className='generateLinkDivFor' onClick={() => { setConfirmationModal(true) }}>
                  {propData?.state?.editResume ? "Update" : "Submit"}
                </button> :
                  <button type='button' className='generateLinkDivFor' onClick={() => { handleAfterSubmitActions('link') }}>
                    <img src={Attachment} alt="" />  Copy Resume Link
                  </button>}
                {(propData?.state?.editResume && hasSubmitted) && <div className='buttonDivforIcon'>
                  <button type="button" className="iconButton" onClick={() => { handleAfterSubmitActions('downlaodPdf') }}>
                    <img src={DownloadIcon} alt='' />
                  </button>
                  <button type="button" className="iconButton" onClick={() => { handleAfterSubmitActions('generateQr') }}>
                    <img src={QRCodeIcon} alt='' />
                  </button>
                </div>}
              </>
            )}
            {navigationStatus > 1 && (!hasSubmitted || propData?.state?.editResume) && (
              <button type="button" className="nextButton" onClick={handlePrevDetails}>
                Back
              </button>
            )}
          </div>
          {/* <div className='webCopyrightDiv' style={{position : 'absolute', bottom : 0}}>ShareResume.co powered by&nbsp;<span className="hyperlinkQuikHire">QuikHire.ai</span></div> */}
        </div>
      </div>}
      <div ref={container} style={{ height: "0", overflow: 'hidden' }}>
        <PDFExport forcePageBreak=".page-break" keepTogether='.together' ref={pdfExportComponent}
          paperSize="A4" margin={{ top: 20, left: 20, right: 20, bottom: 0 }}
          author="KendoReact Team" fileName={`${allResumeDetails?.firstName}'s Resume`}>
          <ConstantScreen.MobileResumePreview allResumeDetails={allResumeDetails} base64Img={base64Img} />
          {/* {!!allResumeDetails?.summaryAudio && <div>
              <div style={{display:"flex",margin:"10px auto",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"5px"}}>
              <h3 style={{fontWeight:"normal",fontSize:"1rem",margin:"0px"}}>Audio Preview:</h3>
              <audio controls>
               <source src={allResumeDetails?.summaryAudio?.name? URL.createObjectURL(allResumeDetails?.summaryAudio) : allResumeDetails?.summaryAudio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
            </div>
         } */}
        </PDFExport>
      </div>

      <ConstantScreen.Modal
        isOpen={sucessModal?.isRequired}

        className="SlabModal"
        onRequestClose={CloseSuccesModel}
        generatedLink={sucessModal?.generatedLinks}
        hintText={sucessModal?.hintText}
        successMsg={sucessModal?.successMsg}
        wrongMsg={sucessModal?.wrongMsg}
        deleteMsg={sucessModal?.deleteMsg}
        okBtnFunCondition={sucessModal.okBtnFunCondition}
        modelOkBtnFun={sucessModal?.okBtnFunction} />


      <Modal
        isOpen={errModal}
        onRequestClose={() => setErrModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className='modalCloseDiv' ><span onClick={() => setErrModal(false)}>X</span></div>
        <div className='modalLottieDiv'>
          <Lottie className='lottieFile' animationData={Error} loop={true} />
        </div>
        <div className='modalMsg'>{modalMsg}</div>
        <button className='modalOkBtn' onClick={() => setErrModal(false)}>OK</button>
      </Modal>
      <Modal
        isOpen={lgOutPopup}
        onRequestClose={() => setLgOutPopup(false)}
        ariaHideApp={false}
        style={customStylesModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv" style={{ backgroundColor: "#FFF4F4" }}>
            <p onClick={() => setLgOutPopup(false)} className="delAccModalCrossPara" style={{ color: "#BC0000" }}>×</p>
          </div>
        </div>
        <div className='logoutLogoDiv' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={logOutLogo} />
        </div>
        <div className="delAccModalErrMsg" style={{ fontWeight: "normal", marginBottom: 0 }}>
          Would you like to logout?
        </div>
        <div className="okBtnModal" style={{ display: "flex", gap: "2%", margin: '0 0 2% 0' }}>
          <button onClick={() => setLgOutPopup(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #BC0000", color: "#BC0000", cursor: "pointer" }}>Cancel</button>
          <button style={{ backgroundColor: "#BC0000", cursor: "pointer" }} onClick={handleLogout}>Logout</button>
        </div>
      </Modal>

      <Modal
        isOpen={successModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setsuccessModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className='modalCloseDiv'><span onClick={() => setsuccessModal(false)}>X</span></div>
        <div className='modalLottieDiv'>
          <Lottie className='lottieFile' animationData={Success} loop={true} />
        </div>
        <div className='modalMsg'>{modalMsgSuccess}</div>
        <button
          className='modalOkBtn'
          onClick={() => {
            setsuccessModal(false)
            if (modalMsg.toLowerCase().includes('updated') || modalMsg.toLowerCase().includes('created')) {
              navigate('/Main/myResume')
            }
          }}>
          OK
        </button>
      </Modal>

      <Modal
        isOpen={confirmationModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setConfirmationModal(false)}
        style={customStyles1}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className='modalCloseDiv'><span onClick={() => setConfirmationModal(false)}>X</span></div>
        <div className='modalLottieDiv mbottomEdu'>
          {/* <img src={confirmSubmitResume} alt='Something Went Wrong.' /> */}
        </div>

        <span className='questionChange'>Are you want to customize profile Url</span>
        <div className='modalMsg resumeURLModal'>
          <span style={{ wordBreak: 'break-all' }}>{ResumeWebURL}</span> <input maxLength={20} type="text" value={userUid} onBlur={(e) => { ValidateprofileUrl(e.target.value) }} onChange={(e) => { handleuserIDChange(e) }} />
          {userUIDError &&
            <small className='resumeUrlError'>{profileMsg}</small>
          }
          {(userUid?.length < 10 || userUid?.length > 20) &&
            <small className='resumeUrlError'>Your Custom URL must contain 10-20 alphanumeric characters only.</small>
          }
        </div>
        <p className='infoState'>
          Note : You can create your own custome profile for url.
        </p>
        <div className='confirmSubmitBtns'>
          <button onClick={handleUploadProfileImage}>Submit</button>
          {/* <button onClick={submitWithoutURLChange}>Submit Without Change</button> */}
        </div>
      </Modal>
      <ConstantScreen.LoaderModal setLoader={loader} />
    </div>
  );
};

export default Mobile;
