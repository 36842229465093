import './App.css';
import { SelectedCardProvider } from './Context/Context';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ComponentAndScreenConstant } from './constants/componentAndScreenConstant';
import { ConstantScreen } from './Screens/ResumeBuilder/ResumeScreensAndComponents/ConstantScreen';

function App() {
  return (
    <SelectedCardProvider>
      <HashRouter>
        <Routes>
          <Route path='/' element={<Navigate to='login' />} />
          <Route path='/login' element={<ComponentAndScreenConstant.Login />} />
          <Route path='/register' element={<ComponentAndScreenConstant.Register />} />
          <Route path='/Main/*' element={<ComponentAndScreenConstant.ApplicantDashboard />} />
          <Route path='/suggestedPref' element={<ComponentAndScreenConstant.SuggestedJobsPref />} />
          
          {/* resume_builder_interceptor */}
          <Route path='/resume/view/:userId' element={<ConstantScreen.ResumeViewPage />} />
          <Route path='/resume/Main/*' element={window.screen.width > 1023 ? <ConstantScreen.Web /> : <ConstantScreen.Mobile />} />
        </Routes>
      </HashRouter>
    </SelectedCardProvider>
  );
}

export default App;
