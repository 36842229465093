import '../Register/Register.css'
import { SHA256 } from 'crypto-js';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { useSelectedCardContext } from '../../Context/Context';
import { PortalUserRole, ResumeWebURL, Url } from '../../EnvoirnmentSetup/APIs';
import { getAPICall, postAPICall } from '../../APIMethods/APIMethods';
import { IoMdRadioButtonOn, IoMdRadioButtonOff } from "react-icons/io";
import { Calendlyaccount, emailValidationForJobPoster, field, onlyAlphabets, onlyLinkedInProfile, onlyNumber } from '../../Validation/Validation';

import eyeIcon from '../../assets/eyeIcon.svg'
import { FaCircleInfo } from "react-icons/fa6";
import Qregister from '../../assets/Qregister.svg'
import HireMeLogo from '../../assets/quikHireLogo.png'
import hiddenEyeIcon from '../../assets/hiddenEyeIcon.svg'
import { getFullUrl } from '../../constants/constantFunctions';

const Register = () => {
  const navigate = useNavigate()
  const { setIsLoading, setWarning, setAlert } = useSelectedCardContext()

  const [otp, setOtp] = useState('');
  const [otp2, setOtp2] = useState('');
  const [pwdScreen, setPwdScreen] = useState(false)
  const [resendOtp, setResendOtp] = useState({ mobile: false, email: false })
  const [auditId, setAuditId] = useState({ messageAuditId: "", emailAuditId: "" })
  const [hidePwd, setHidePwd] = useState({ npwd: false, cnpwd: false })
  const [isOtpSent, setIsOtpSent] = useState({ mno: false, email: false })
  const [countryCodes, setCountryCodes] = useState([])
  const [userIDEditUser, setUserIDEditUser] = useState("")
  const [errMsg, setErrMsg] = useState({ fullNameErr: "", mnoErr: "", emailErr: "", linkedInProfileErr: "", pwdErr: "", cpwdErr: "" })
  const [newUserDetails, setNewUserDetails] = useState({
    fullName: field, cntryCode: "249",
    mno: field, email: field,
    mnoChkBx: true, emailChkBx: true,
    linkedInProfile: { ...field, fieldValue: "https://www.linkedin.com/in/" },
    calendlyProfile: { ...field, fieldValue: "https://calendly.com/" },
    pwd: "", cpwd: ""
  })
  const [invalidOTP, setInvalidOTP] = useState({ mno: false, email: false })
  const [isLinkedIn, setIsLinkedIn] = useState(true)

  useEffect(() => {
    let fullURL = window.location.href;
    fullURL = fullURL.replace("#", "")
    const url = new URL(fullURL);

    let userParamsId = !!url.searchParams.get("userid") ? atob(url.searchParams.get("userid")) : "";
    let userParamsFullName = !!url.searchParams.get("username") ? atob(url.searchParams.get("username")) : "";
    let userParamsCCId = !!url.searchParams.get("CCId") ? atob(url.searchParams.get("CCId")) : "";
    let userParamsmobNumber = !!url.searchParams.get("mobilenumber") ? atob(url.searchParams.get("mobilenumber")) : "";
    let userParamsEmail = !!url.searchParams.get("email") ? atob(url.searchParams.get("email")) : "";

    setUserIDEditUser(userParamsId)
    if (sessionStorage.getItem("token")?.length > 0) {
      navigate("/Main/latestJob");
    }
    if (!!userParamsId) {
      setNewUserDetails({ ...newUserDetails, mno: onlyNumber('Mobile Number', userParamsmobNumber, 10), cntryCode: userParamsCCId, fullName: onlyAlphabets('Full Name', userParamsFullName), email: emailValidationForJobPoster('Email Address', userParamsEmail) })
    } else {
      navigate('/login')
    }

    getAPICall(Url.CountryCodes)
      .then((res) => setCountryCodes(res?.DATA))
      .catch(e=>console.log(e))

  }, [])

  useEffect(() => {
    handleOtpVerify()
  }, [otp])

  // useEffect(() => {
  //   handleOtpVerifyForEmail()
  // }, [otp2])

  var bothUpperLowerCase = /^(?=.*[a-z])(?=.*[A-Z]).+$/
  var lowerCase = /[a-z]/
  var upperCase = /[A-Z]/
  var oneDigitRegex = /[0-9]/;
  var oneSpclChar = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~`]).+$/
  const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|gmail\.|hotmail\.)(?!yahoo|gmail|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  let passwordErrorMessages={
    isRequired: "Password is required.",
    minLength: "Minimum length should be 8.",
    maxLength: "Maximum length should be 16.",
    oneDigit: "Should accept at least one digit is required.",
    oneSpecial: "Should accept at least one special character is required.",
    oneLowerCase: "Should accept at least one lower case is required.",
    oneUpperCase: "Should accept at least one upper case is required.",
    confirmPassword: "Confirm password is required.",
    notMatch: "Password doesn't match.",
  }
  
  const handleRegInputChange = (e, field) => {
    if (field == "cntryCode") {
      setNewUserDetails({ ...newUserDetails, cntryCode: e?.target?.value })
    }
    else if (field == "mnoChkBx") {
      setNewUserDetails({ ...newUserDetails, mnoChkBx: !newUserDetails.mnoChkBx })
    }
    else if (field == "emailChkBx") {
      setNewUserDetails({ ...newUserDetails, emailChkBx: !newUserDetails.emailChkBx })
    }
    else if (field == "pwd") {
      if (e?.target?.value == "") {
        setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.isRequired })
      }
      else if (!upperCase.test(e?.target?.value)) {
        setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.oneUpperCase })
      }
      else if (!lowerCase.test(e?.target?.value)) {
        setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.oneLowerCase })
      }
      else if (!oneDigitRegex.test(e?.target?.value)) {
        setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.oneDigit })
      }
      else if (!oneSpclChar.test(e?.target?.value)) {
        setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.oneSpecial })
      }
      else if (e?.target?.value.length < 8) {
        setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.minLength })
      }
      else if (e?.target?.value.length > 16) {
        setErrMsg({ ...errMsg, pwdErr: passwordErrorMessages.maxLength })
      }
      else {
        setErrMsg({ ...errMsg, pwdErr: "" })
      }
      setNewUserDetails({ ...newUserDetails, pwd: e?.target?.value })
    }
    else if (field == "cpwd") {
      if (e?.target?.value == "") {
        setErrMsg({ ...errMsg, cpwdErr: passwordErrorMessages.confirmPassword })
      }
      else if (e?.target?.value !== newUserDetails?.pwd) {
        setErrMsg({ ...errMsg, cpwdErr: passwordErrorMessages.notMatch })
      } else {
        setErrMsg({ ...errMsg, cpwdErr: "" })
      }
      setNewUserDetails({ ...newUserDetails, cpwd: e?.target?.value })
    }
  }

  const onTextChange = (fields, val) => {
    switch (fields) {
      case 'Full Name':
        setNewUserDetails({
          ...newUserDetails,
          fullName: onlyAlphabets(fields, val),
        });
        break;
      case 'Email Address':
        setAuditId({ ...auditId, emailAuditId: "" })
        setResendOtp({ ...resendOtp, email: false })
        setOtp2('')
        setNewUserDetails({
          ...newUserDetails,
          email: emailValidationForJobPoster(fields, val),
        });
        break;
      case 'Mobile Number':
        setAuditId({ ...auditId, messageAuditId: "" })
        setResendOtp({ ...resendOtp, mobile: false })
        setOtp('')
        if (val.length <= 10) {
          setNewUserDetails({
            ...newUserDetails,
            mno: onlyNumber(fields, val, 10),
          })
        }
        break;
      case 'LinkedIn Profile':
        setNewUserDetails({
          ...newUserDetails,
          linkedInProfile: onlyLinkedInProfile(fields, val),
        });
        break;
      case 'Calendly Profile':
        setNewUserDetails({
          ...newUserDetails,
          calendlyProfile: Calendlyaccount(fields, val),
        });
        break;
    }
  };

  const getUserDataname = () => {
    setIsLoading(true)
    getAPICall(Url.getuser)
      .then((res) => {
        setIsLoading(false)
        sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName))
        sessionStorage.setItem("user", JSON.stringify(res?.DATA));
        let companyDetailsAdded = null
        if (res?.DATA?.company?.length > 0) {
          companyDetailsAdded = true
        } else if (res?.DATA?.company?.length == 0) {
          companyDetailsAdded = false
        }
        navigate("/Main/latestJob");
        // navigate(`/Main/userProfile`, { state: { headerHide: true } });
        }).catch(e=>console.log(e))
  }
  const handleRegisterUser = () => {
    if (newUserDetails?.pwd == "") {
      setWarning("Password field is required.")
    }
    else if (!upperCase.test(newUserDetails?.pwd)) {
      setWarning("Password should contain at least one uppercase letter.")
    }
    else if (!lowerCase.test(newUserDetails?.pwd)) {
      setWarning("Password should contain at least one lowercase letter.")
    }
    else if (!oneDigitRegex.test(newUserDetails?.pwd)) {
      setWarning("Password should contain at least one digit letter.")
    }
    else if (!oneSpclChar.test(newUserDetails?.pwd)) {
      setWarning("Password should contain at least one special character.")
    }
    else if (newUserDetails?.pwd.length < 8) {
      setWarning("Password minimum length should be 8.")
    }
    else if (newUserDetails?.pwd.length > 16) {
      setWarning("Password maximum length should be 16.")
    }
    else if (newUserDetails?.cpwd == "") {
      setWarning("Confirm Password is required.")
    }
    else if (newUserDetails?.cpwd != newUserDetails?.pwd) {
      setWarning("Password doesn't match.")
    } else if (
      newUserDetails?.linkedInProfile?.fieldValue == '' ||
      newUserDetails?.linkedInProfile?.isValidField == false ||
      newUserDetails?.linkedInProfile?.fieldValue == "https://www.linkedin.com/in/" ||
      newUserDetails?.linkedInProfile?.fieldValue == "https://www.shareresume.co/#/"
    ) {
      setWarning(isLinkedIn ? "Valid linkedIn profile is required." : "Valid resume URL is required.");
    }
    else {
        let updatedLinkedInUrl = getFullUrl(newUserDetails?.linkedInProfile?.fieldValue)

        const obj = {
          userId: !!userIDEditUser ? userIDEditUser : null,
          messageAuditId: auditId?.messageAuditId,
          messageOTP: otp,
          emailAuditId: auditId?.emailAuditId,
          // emailOTP: otp2,
          messageConsent: newUserDetails?.mnoChkBx,
          emailConsent: newUserDetails?.emailChkBx,
          web: true,
          fullName: newUserDetails?.fullName?.fieldValue,
          mobileNumber: newUserDetails?.mno?.fieldValue,
          userName: newUserDetails?.email?.fieldValue?.trim()?.toLocaleLowerCase(),
          password: SHA256(newUserDetails?.pwd).toString(),
          linkedinProfile: updatedLinkedInUrl?.trim() == 'https://www.linkedin.com/in/' ? null : updatedLinkedInUrl?.trim()?.toLocaleLowerCase(),
          countryCode: {
            countryCodeId: newUserDetails?.cntryCode
          },
          calendlyProfile: newUserDetails?.calendlyProfile?.fieldValue?.trim() == 'https://calendly.com/' ? null : newUserDetails?.calendlyProfile?.fieldValue?.trim()?.toLocaleLowerCase(),
          roles: [
            { name: PortalUserRole }
          ]
        }
        postAPICall(Url.registerUser, obj)
          .then((res) => {
            if (res?.SUCCESS) {
              handleAutoLogin()
              // setAlert("Registration completed successfully.")
            }
            else {
              setWarning(res?.message)
            }
          }).catch(e=>console.log(e))
    }
  }
  const handleSendOtp = () => {
    if (!(!!newUserDetails?.mno?.fieldValue)) {
      setWarning("Please enter the mobile number.")
    } else if (!newUserDetails?.mnoChkBx) {
      setWarning("Please select the checkbox to receive text messages.")
    } else if (newUserDetails?.mno?.fieldValue.length < 6) {
      setWarning('Enter Valid Mobile Number')
    }
    else {
        let obj = {
          countryCodeId: newUserDetails?.cntryCode,
          toNumber: newUserDetails?.mno?.fieldValue,
          messageType: "OTP_SMS",
          registerd: false
        }
        postAPICall(Url.SendOtp, obj)
          .then((res) => {
            if (res.SUCCESS) {
              setAlert("OTP sent successfully.")
              setAuditId({ ...auditId, messageAuditId: res?.DATA })
              setResendOtp({ ...resendOtp, mobile: true })
              setIsOtpSent({ ...isOtpSent, mno: true })
              setOtp('')
            } else {
              setWarning(res?.message)
            }
          }).catch(e=>console.log(e))
    }
  }

  // const handleEmailOtpSend = () => {
  //   if (!(!!newUserDetails?.email?.fieldValue)) {
  //     setWarning("Please enter the Email Id.")
  //   } else if (!newUserDetails?.emailChkBx) {
  //     setWarning("Please select the checkbox to receive an email.")
  //   } else if (!!newUserDetails?.email?.errorField) {
  //     setWarning(newUserDetails?.email?.errorField)
  //   } else {
  //       let obj = {
  //         toEmail: newUserDetails?.email?.fieldValue,
  //         messageType: "OTP_EMAIL",
  //         registerd: false
  //       }
  //       postAPICall(Url.SendOtp, obj)
  //         .then((res) => {
  //           if (res?.SUCCESS) {
  //             setAlert("OTP sent successfully.")
  //             setAuditId({ ...auditId, emailAuditId: res?.DATA })
  //             setIsOtpSent({ ...isOtpSent, email: true })
  //             setResendOtp({ ...resendOtp, email: true })
  //             setOtp2('')
  //           } else {
  //             setWarning(res?.message)
  //           }
  //         }).catch(e=>console.log(e))
  //   }
  // }

  const handleAutoLogin = () => {
      let obj = {
        countryCodeId: newUserDetails?.cntryCode,
        username: newUserDetails?.mno?.fieldValue,
        password: SHA256(newUserDetails?.pwd).toString(),
        role: PortalUserRole.replace('ROLE_', '')
      }
      setIsLoading(true)
      postAPICall(Url?.UserLogin, obj)
        .then((ress) => {
          setIsLoading(false)
          if (ress?.SUCCESS) {
            setNewUserDetails({
              fullName: field, cntryCode: "249", mno: field, email: field, mnoChkBx: true, emailChkBx: true,
              linkedInProfile: { ...field, fieldValue: "https://www.linkedin.com/in/" }, pwd: "", cpwd: ""
            })
            let token = ress?.token
            sessionStorage.setItem("token", token)
            getUserDataname()
          }
          else {
            setWarning(ress?.message)
          }
        }).catch(e=>console.log(e))
  }

  const handleOtpVerify = () => {
    if (otp?.length == 4) {
      if (auditId?.messageAuditId == "") {
        setWarning("Please send the OTP to verify mobile number.")
      } else {
          let obj = {
            messageAuditId: auditId?.messageAuditId,
            OTP: otp
          }
          postAPICall(Url.otpVerify, obj)
            .then((res) => {
              if (res?.SUCCESS == true && res?.DATA == true) {
                setAlert("Mobile No. OTP verified successfully.")
                setInvalidOTP({ ...invalidOTP, mno: false })
              } else {
                setInvalidOTP({ ...invalidOTP, mno: true })
                setWarning(res?.message)
              }
            }).catch(e=>console.log(e))
      }
    }
  }
  // const handleOtpVerifyForEmail = () => {
  //   if (otp2?.length == 4) {
  //     if (auditId?.emailAuditId == "") {
  //       setWarning("Please send the OTP to verify email.")
  //     } else {
  //         let obj = {
  //           messageAuditId: auditId?.emailAuditId,
  //           OTP: otp2
  //         }
  //         postAPICall(Url.otpVerify, obj)
  //           .then((res) => {
  //             if (res?.SUCCESS == true && res?.DATA == true) {
  //               setAlert("Email OTP verified successfully.")
  //               setInvalidOTP({ ...invalidOTP, email: false })
  //             } else {
  //               setInvalidOTP({ ...invalidOTP, email: true })
  //               setWarning(res?.message)
  //             }
  //           }).catch((e) => console.log(e))
  //     }
  //   }
  // }
  const handleShowNpwd = () => {
    setHidePwd({ ...hidePwd, npwd: !hidePwd?.npwd })
  }
  const handleShowCnpwd = () => {
    setHidePwd({ ...hidePwd, cnpwd: !hidePwd?.cnpwd })
  }
  const handleNxtScreen = () => {
    console.log('all auditIds..', auditId, '\n', isOtpSent, invalidOTP);
    if (!(!!newUserDetails?.fullName?.fieldValue)) {
      setWarning("Full Name is required.")
    }
    else if (!newUserDetails?.fullName?.isValidField) {
      setWarning(newUserDetails?.fullName?.errorField)
    }
    else if (!(!!newUserDetails?.mno?.fieldValue)) {
      setWarning("Mobile No. is required.")
    }
    else if (newUserDetails?.mno?.fieldValue.length <= 9) {
      setWarning('Mobile No. is invalid.')
    }
    // else if (newUserDetails?.mno.length < 4) {
    //   setWarning("Mobile No. minimum length should be 4")
    // }
    // else if (newUserDetails?.mno.length > 10) {
    //   setWarning("Mobile No. maximum length should be 10")
    // }
    else if (!newUserDetails?.mnoChkBx) {
      setWarning("Please select the checkbox to receive text messages.")
    }
    else if (!isOtpSent.mno) {
      setWarning("Please send the OTP to verify Mobile no.")
    }
    else if (otp == "") {
      setWarning("OTP is required to verify Mobile No.")
    }
    else if (otp.length < 4) {
      setWarning("Invalid OTP provided to verify Mobile No.")
    }
    else if (invalidOTP?.mno) {
      setWarning("The entered Mobile No. OTP is invalid.")
    }
    else if (!(!!newUserDetails?.email?.fieldValue)) {
      setWarning("Email is required.")
    }
    else if (!newUserDetails?.email?.isValidField) {
      setWarning(newUserDetails?.email?.errorField)
    }
    else if (newUserDetails?.emailChkBx == false) {
      setWarning("Please select the checkbox to receive an email.")
    }
    // else if (isOtpSent.email == false) {
    //   setWarning("Please send the OTP to verify Email id.")
    // }
    // else if (otp2 == "") {
    //   setWarning("OTP is required to verify Email.")
    // }
    // else if (otp2.length < 4) {
    //   setWarning("Invalid OTP provided to verify Email.")
    // }
    // else if (invalidOTP?.email) {
    //   setWarning("The entered Email OTP is invalid.")
    // }
    else if (!newUserDetails?.linkedInProfile?.isValidField) {
      setWarning(newUserDetails?.linkedInProfile?.errorField)
    }
    else if (
      newUserDetails?.linkedInProfile?.fieldValue == '' ||
      newUserDetails?.linkedInProfile?.fieldValue == "https://www.linkedin.com/in/" ||
      newUserDetails?.linkedInProfile?.fieldValue == "https://www.shareresume.co/#/"
    ) {
      setWarning(isLinkedIn ? "Enter Valid linkedIn profile is required." : "Enter Valid resume URL is required.");
    }
    // else if (newUserDetails?.calendlyProfile?.fieldValue == "https://calendly.com/") {
    //   setWarning("Please provide valid Calendly Profile URL.")
    // }
    else {
      setPwdScreen(true)
    }
  }

  return (
    <div className='RegOuterDiv'>
      <div className='RegImgDiv'>
        <div className='OverlayNew'>
          <div className='RegImgDivTxt'>
            <div className='HiringMore'>Make Campus Recruitment</div>
            <div className='humanTxt'>Seamlessly</div>
            <p> Welcome to CampusPlacement.in – revolutionizing campus recruitment by seamlessly connecting students and college recruiters through innovative processes and technology. Join us in transforming the way talent meets opportunity, making the campus placement process smooth and efficient for everyone involved.</p>
          </div>
        </div>
      </div>
      <div className='RegFormDiv'>
        <div className='RegFormInnerDiv'>
          <div className='RegFixedContainer'>
            <div className='RegLogoDiv'>
              <img src={HireMeLogo} />
            </div>
            <div className='RegAccTxt'>Registration</div>
            <div className='RegCreateAcc'>
              <span>Create an account to apply for job openings</span>
              <span> and explore exciting career opportunities.</span>
            </div>
          </div>
          {!pwdScreen ?
            <>
              <div className='RegScrollableContent'>
                <div className='RegFieldDiv'>
                  <label>Full Name<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <input
                    maxLength={60}
                    value={newUserDetails?.fullName?.fieldValue}
                    className='RegInput'
                    placeholder='Enter your full name'
                    onChange={(e) => onTextChange("Full Name", e?.target?.value)} />
                  <small className='RegErrMsg'>{newUserDetails?.fullName?.errorField}</small>
                </div>
                <div className='RegFieldDiv mtop' style={{ marginBottom: 0 }}>
                  <label>Mobile No<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <div className='RegMnoDiv'>
                    <select disabled value={newUserDetails?.cntryCode} onChange={(e) => handleRegInputChange(e, "cntryCode")}>
                      {countryCodes.map((item) => <option value={item?.countryCodeId}>+{item?.countryCode}</option>)}
                    </select>
                    <input
                      maxLength={14}
                      pattern="\d{1,10}"
                      inputmode="numeric"
                      readOnly={!!userIDEditUser}
                      placeholder='Enter your mobile number'
                      value={newUserDetails?.mno?.fieldValue}
                      onChange={(e) => onTextChange("Mobile Number", e?.target?.value)}
                    />
                  </div>
                  <small className='RegErrMsg'>{newUserDetails?.mno?.errorField}</small>
                </div>
                <div className='RegChkBxDiv'>
                  <div className='RegChkBxSubDiv'>
                    <input
                      type='checkbox'
                      role='mnoChkBx'
                      checked={newUserDetails?.mnoChkBx}
                      className='RegChkBx'
                      onChange={(e) => handleRegInputChange(e, "mnoChkBx")} />
                  </div>
                  <div className='RegChkBxContent'>
                    By checking the check box, you agree to receive text messages from Campus Placement.
                  </div>
                </div>
                {!resendOtp?.mobile ?
                  <div role='mobileSendOtp' className='RegResendOtpDiv'><span onClick={handleSendOtp}>Send OTP</span></div>
                  :
                  <div role='mobileResendOtp' className='RegResendOtpDiv'><span onClick={handleSendOtp}>Resend OTP</span></div>
                }

                <div className='RegOtpDiv'>
                  <label>OTP<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <div className='RegOtpInput'>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      role='mobileOTP'
                      renderSeparator={<span className='separatorWidth'></span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle="regOTPinputField"
                      inputType='number'
                    />
                  </div>
                </div>
                <div className='RegFieldDiv mtop' style={{ marginBottom: 0, marginTop: "3%" }}>
                  <label>Email Address<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <input
                    maxLength={40}
                    type='email'
                    inputmode="email"
                    readOnly={!!userIDEditUser}
                    value={newUserDetails?.email?.fieldValue}
                    className='RegInput'
                    placeholder='Enter your email address'
                    onChange={(e) => onTextChange("Email Address", e?.target?.value)} />
                  <small className='RegErrMsg'>{newUserDetails?.email?.errorField}</small>
                </div>
                {/* <div className='RegChkBxDiv'>
                  <div className='RegChkBxSubDiv'>
                    <input
                      type='checkbox'
                      className='RegChkBx'
                      role='emailChkBx'
                      checked={newUserDetails?.emailChkBx}
                      onChange={(e) => handleRegInputChange(e, "emailChkBx")} />
                  </div>
                  <div className='RegChkBxContent'>
                    By checking the check box, you agree to receive emails from Campus Placement.
                  </div>
                </div>
                {!resendOtp?.email ?
                  <div role='emailSendOtp' className='RegResendOtpDiv' onClick={handleEmailOtpSend}>Send OTP</div>
                  :
                  <div role='emailResendOtp' className='RegResendOtpDiv' onClick={handleEmailOtpSend}>Resend OTP</div>
                }

                <div className='RegOtpDiv'>
                  <label>OTP<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <div className='RegOtpInput'>
                    <OtpInput
                      value={otp2}
                      onChange={setOtp2}
                      numInputs={4}
                      role='emailOTP'
                      renderSeparator={<span className='separatorWidth'></span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle="regOTPinputField"
                      inputType='number'
                    />
                  </div>
                </div> */}
                <div className='RegFieldDiv mtop' style={{ marginTop: "3.5%" }}>
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <label
                      data-testid="linkedin-profile-label"
                      onClick={() => {
                        setIsLinkedIn(true)
                        setNewUserDetails((v) => ({
                          ...v,
                          linkedInProfile: { ...field, fieldValue: 'https://www.linkedin.com/in/' }
                        }))
                      }}
                      style={{ display: 'flex', gap: '3px', alignItems: 'center', cursor: 'pointer' }}>
                      {isLinkedIn ? <IoMdRadioButtonOn size={'1.5rem'} color='#01774A' /> : <IoMdRadioButtonOff size={'1.5rem'} color='#000' />}
                      LinkedIn Profile<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                    </label>
                    <label
                      data-testid="resume-url-label"
                      onClick={() => {
                        setIsLinkedIn(false)
                        setNewUserDetails((v) => ({
                          ...v,
                          linkedInProfile: { ...field, fieldValue: 'https://www.shareresume.co/#/' }
                        }))
                      }}
                      style={{ display: 'flex', gap: '3px', alignItems: 'center', cursor: 'pointer' }}>
                      {!isLinkedIn ? <IoMdRadioButtonOn size={'1.5rem'} color='#01774A' /> : <IoMdRadioButtonOff size={'1.5rem'} color='#000' />}
                      Resume URL<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                    </label>
                  </div>
                  <input
                    type='text'
                    className='RegInput'
                    value={newUserDetails?.linkedInProfile?.fieldValue}
                    placeholder={isLinkedIn ? 'Enter LinkedIn Profile' : 'Enter Resume URL'}
                    onChange={(e) => onTextChange("LinkedIn Profile", e?.target?.value)} />
                  <small className='RegErrMsg'>{newUserDetails?.linkedInProfile?.errorField}</small>

                  <button
                    style={{ border: 'none', outline: 'none', color: '#00000090', backgroundColor: 'transparent', display: 'flex', alignItems: 'center', alignSelf: 'end', gap: '4px', fontSize: '1rem' }}
                    onClick={() => { window.open(isLinkedIn ? 'https://www.youtube.com/watch?v=NMRoM0ndAtU' : 'https://www.shareresume.co/#/Dashboard', '_blank'); }}
                  >
                    <FaCircleInfo /> {isLinkedIn ? 'Help' : 'Create Resume'}
                  </button>
                </div>
                {/* <div className='RegFieldDiv mtop' style={{ marginTop: "3.5%" }}>
                  <label>Calendly Profile</label>
                  <input
                    type='text'
                    className='RegInput'
                    placeholder='https://calendly.com/...'
                    value={newUserDetails?.calendlyProfile?.fieldValue}
                    onChange={(e) => onTextChange("Calendly Profile", e?.target?.value)} />
                  <small className='RegErrMsg'>{newUserDetails?.calendlyProfile?.errorField}</small>
                </div> */}
                <div className='RegBtnDiv'>
                  <button onClick={handleNxtScreen}>Next</button>
                </div>
              </div>
              <div className='RegAlreadyAcc'>
                Already have an account? <span onClick={() => navigate('/login')}>Login</span>
              </div>
            </> :
            <div className='RegScrollableContent'>
              <div className='RegFieldDiv mtop' style={{ position: "relative" }}>
                <label>Password<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                <input
                  className='RegInput'
                  value={newUserDetails?.pwd}
                  placeholder='Enter your password'
                  type={hidePwd?.npwd == false ? "password" : "text"}
                  onChange={(e) => handleRegInputChange(e, "pwd")} />
                <small className='RegErrMsg'>{errMsg?.pwdErr}</small>
                <img
                  alt="passwordEyeIcon"
                  className={errMsg?.pwdErr == "" ? 'eyeIcon' : 'eyeIcon1'}
                  src={hidePwd.npwd == true ? eyeIcon : hiddenEyeIcon}
                  onClick={handleShowNpwd} />
              </div>
              <div className='RegFieldDiv mtop' style={{ position: "relative" }}>
                <label>Confirm Password<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                <input
                  className='RegInput'
                  value={newUserDetails?.cpwd}
                  placeholder='Confirm your password'
                  type={hidePwd?.cnpwd == false ? "password" : "text"}
                  onChange={(e) => handleRegInputChange(e, "cpwd")} />
                <small className='RegErrMsg'>{errMsg?.cpwdErr}</small>
                <img
                  alt="confirmEyeIcon"
                  className={errMsg?.cpwdErr == "" ? 'eyeIcon' : 'eyeIcon1'}
                  src={hidePwd.cnpwd == true ? eyeIcon : hiddenEyeIcon}
                  onClick={handleShowCnpwd} />
              </div>
              <div className='RegBtnDiv'>
                <button onClick={handleRegisterUser}><img src={Qregister} alt='Complete Register' /></button>
              </div>
              {/* <div className='quikRegDiv' onClick={handleRegisterUser}>
                <img src={quik} alt='loading'/>
                <span>Register</span>
              </div> */}
              <div className='RegBtnDiv'>
                <button onClick={() => setPwdScreen(false)}>Back</button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Register