import "./UserProfile.css";
import Modal from "react-modal";
import Lottie from "lottie-react";
import { SHA256 } from 'crypto-js';
import React, { useEffect, useState } from "react";
import { ModuleCards } from "../../constants/ModuleCards";
import { useSelectedCardContext } from "../../Context/Context";
import { PortalUserRole, ResumeApiUrls, ResumeWebURL, Url } from "../../EnvoirnmentSetup/APIs";
import { emailValidationForJobPoster, field, matchPassword, onEmailChange, onPassword, onlyAlphabets, onlyLinkedInProfile, onlyNumber } from "../../Validation/Validation";
import eyeicon from '../../assets/eyeIcon.svg'
import hiddenEyeIcon from '../../assets/hiddenEyeIcon.svg'
import lock from '../../assets/lock.svg'
import employees from '../../assets/Employees.png'
import phoneCallImage from "../../assets/phoneCallImage.svg";
import emailImage from "../../assets/emailImage.svg";
import linkedInImage from "../../assets/linkedInImage.svg";
import editprofile from "../../assets/editprofile.svg";
import { getAPICall, patchAPICall, putAPICall } from "../../APIMethods/APIMethods";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { IoMdRadioButtonOn, IoMdRadioButtonOff } from "react-icons/io";
import { FaCircleInfo } from "react-icons/fa6";
import { getFullUrl } from "../../constants/constantFunctions";
// import jobPreferenceIcon from "../../assets/jobPreference.svg"
import { useNavigate } from "react-router-dom";
import { ReactComponent as JobPreference } from '../../assets/jobPreference.svg'

function UserProfile() {

    const navigate = useNavigate()
    const { setSelectedCard, setWarning, setAlert } = useSelectedCardContext()
    const [loader, setLoader] = useState(false)
    const [userId, setUserId] = useState("")
    const [selectedCountryCode, setSelectedCountryCode] = useState({ countryCode: '91', countryCodeId: '249' })
    const [editProfileModal, setEditProfileModal] = useState(false)
    const [changePasswordModal, setChangePasswordModal] = useState(false)

    const [countryCode, setCountryCode] = useState([])
    const [IsResumeAvailable, setIsResumeAvailable] = useState(false)

    const [userDetails, setUserDetails] = useState()
    const [EditUser, setEditUser] = useState({
        fullName: '',
        mobileNumber: '',
        countryCode: null,
        userName: '',
        linkedinProfile: '',
    })

    const [currentPassword, setCurrentPassword] = useState(field)
    const [newPassword, setNewPassword] = useState(field)
    const [confirmPassword, setConfirmPassword] = useState(field)
    const [hidePwd, setHidePwd] = useState({ pwdOne: false, pwdTwo: false, pwdThree: false })
    const [isLinkedIn, setIsLinkedIn] = useState(true)
    const [preferencesModal, setPreferencesModal] = useState(false)
    const [preferenceDetails, setPreferenceDetails] = useState({
        keywords: [],
        location: [],
        experience: {},
        workMode: [],
        jobType: []
    })


    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "50%",
            borderRadius: "25px",
            padding: "10px",
        },
        overlay: {
            zIndex: 1
        },
    };

    useEffect(() => {
        setCurrentPassword(field)
        setNewPassword(field)
        setConfirmPassword(field)
    }, [changePasswordModal])

    useEffect(() => {
        setSelectedCard(ModuleCards.profile)
        getUserCompleteDetails()
        getAPICall(Url.CountryCodes)
            .then((res) => {
                setCountryCode(res?.DATA)
            })
    }, [])

    const getUserCompleteDetails = () => {
        // let user
        setLoader(true)
        getAPICall(Url.getuser)
            .then((res) => {
                setLoader(false)
                setUserId(res?.DATA?.userId)
                setUserDetails(res?.DATA)
                // user = res?.DATA
                setIsLinkedIn(!res?.DATA?.linkedinProfile?.includes('shareresume'))
                sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName));
                sessionStorage.setItem("user", JSON.stringify(res?.DATA));
                if (res?.SUCCESS && res?.DATA?.preferenceSet) {
                    setPreferenceDetails({
                        keywords: res?.DATA?.skill,
                        location: res?.DATA?.state,
                        experience: res?.DATA?.experience,
                        workMode: res?.DATA?.workMode,
                        jobType: res?.DATA?.jobType,
                        preferenceSet: res?.DATA?.preferenceSet
                    })
                }
            })

        // if (!!user) {
        //     getAPICall(ResumeApiUrls.GetResume.replace('{mobile}', user?.mobileNumber).replace('{countryCodeId}', user?.countryCode?.countryCodeId))
        //         .then((ResumeDATA) => {
        //             if (ResumeDATA.SUCCESS && !ResumeDATA?.DATA) {
        //                 setIsResumeAvailable(true)
        //             }
        //         })
        // }
    }
    const updateProfile = () => {
        if (!EditUser?.fullName.isValidField || EditUser?.fullName.fieldValue.trim() == '') {
            setWarning("Invalid Name.")
        } else if (!EditUser?.mobileNumber.isValidField || EditUser?.mobileNumber.fieldValue.trim() == '') {
            setWarning("Invalid mobile number.")
        } else if (!EditUser?.userName.isValidField || EditUser?.userName.fieldValue.trim() == '') {
            setWarning("Invalid email.")
        } else if (
            EditUser?.linkedinProfile?.fieldValue == '' ||
            EditUser?.linkedinProfile?.isValidField == false ||
            EditUser?.linkedinProfile?.fieldValue == "https://www.linkedin.com/in/" ||
            EditUser?.linkedinProfile?.fieldValue == "https://www.shareresume.co/#/"
        ) {
            setWarning(isLinkedIn ? "Valid LinkedIn profile is required." : "Valid Resume URL is required.");
        } else {
            try {
                let updatedLinkedInUrl = getFullUrl(EditUser?.linkedinProfile?.fieldValue)

                setLoader(true)
                const UserData = {
                    userId: userId,
                    messageAuditId: 0,
                    messageOTP: 1111,
                    emailAuditId: 0,
                    emailOTP: 1111,
                    fullName: EditUser?.fullName?.fieldValue?.trim(),
                    mobileNumber: EditUser?.mobileNumber?.fieldValue?.trim(),
                    userName: EditUser?.userName?.fieldValue?.trim()?.toLocaleLowerCase(),
                    linkedinProfile: updatedLinkedInUrl?.trim() == 'https://www.linkedin.com/in/' ? null : updatedLinkedInUrl?.trim()?.toLocaleLowerCase(),
                    countryCode: {
                        countryCodeId: selectedCountryCode?.countryCodeId,
                    },
                    designation: null,
                    state: null,
                    skill: null,
                    experience: null
                }
                console.log('updatedLinkedInUrl1', updatedLinkedInUrl);
                // console.log({ UserData })
                putAPICall(Url.getuser, UserData)
                    .then((res) => {
                        setLoader(false)
                        if (res?.SUCCESS) {
                            setEditProfileModal(false)
                            getUserCompleteDetails();
                            setAlert('Profile updated successfully!');
                            sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName))
                        } else {
                            setWarning(res?.message);
                        }
                    }).catch((e) => console.log(e))
            } catch (e) {
                console.log(e);
            }
        }
    }

    const updatePassword = () => {
        // console.log('update password', currentPassword?.fieldValue, '\nnp', newPassword?.fieldValue, '\nrp', confirmPassword.fieldValue);
        if (!(!!currentPassword?.fieldValue)) {
            setWarning("Please enter old password.")
        } else if (!(!!newPassword?.fieldValue)) {
            setWarning("Please enter new password.")
        } else if (currentPassword?.fieldValue == newPassword.fieldValue) {
            setWarning("Previous password is not accepted")
        } else if (!newPassword?.isValidField) {
            setWarning("Password must contain at least 1 lower case, 1 upper case, 1 numeric, special character, length maximum of 16 and minimum of 8.")
        } else if (!(!!confirmPassword?.fieldValue)) {
            setWarning("Please confirm your password.")
        } else if (confirmPassword.fieldValue != newPassword.fieldValue) {
            setWarning("Password and confirm password do not match.")
        } else if (!confirmPassword.isValidField || confirmPassword.fieldValue.trim().length < 7) {
            setWarning("Confirm Password invalid.")
        } else {
            try {
                let currentPASS = SHA256(currentPassword?.fieldValue).toString();
                let newPASS = SHA256(newPassword?.fieldValue).toString();

                let PassObj = {
                    oldPassword: currentPASS,
                    password: newPASS
                }
                setLoader(true)
                patchAPICall(Url.changePassword, PassObj)
                    .then((res) => {
                        setLoader(false)
                        if (res?.SUCCESS) {
                            getUserCompleteDetails();
                            setChangePasswordModal(false)
                            setAlert(`Password updated successfully!`);
                        } else {
                            setWarning(res?.message);
                        }
                    })
                    .catch((e) => console.log(e))
            } catch (e) {
                console.log(e);
            }
        }
    }

    const handleCloseEditModal = () => {
        setEditProfileModal(false);
        handleEditModal()
    }
    const handleEditModal = (value) => {
        setEditUser({
            fullName: { ...field, fieldValue: value?.fullName ?? '' },
            mobileNumber: { ...field, fieldValue: value?.mobileNumber ?? '' },
            countryCode: null,
            userName: { ...field, fieldValue: value?.userName ?? '' },
            linkedinProfile: { ...field, fieldValue: value?.linkedinProfile ?? '' },
        })
        setSelectedCountryCode(value?.countryCode ?? { countryCode: '91', countryCodeId: '249' })
    }

    const getPreferenceDetails = () => {
        if (preferenceDetails?.preferenceSet) {
            setPreferencesModal(true)
        } else {
            navigate('/suggestedPref')
        }
    }
    return (
        <div className="userProfileWrapper">
            <div className="profileTitleDiv">
                <p className="profileBackBtn">
                    Profile
                </p>
            </div>

            {/* <div className="userHomePageImageWrapper">
                <div className="userNameWrapper">
                    <div className="userHello">Hello,</div>
                    <div className="userName">{userDetails?.fullName ?? ''}</div>
                    <div className="userHello">Student</div>
                </div>
                <img src={employees} className="employeesImg" />
            </div> */}

            <div className="mainDisplyContainer">
                <div className="ContainerOne">
                    <div>
                        <img src={phoneCallImage} className="displyImage" />
                    </div>
                    <div className="showText">+{(userDetails?.countryCode?.countryCode ?? '') + " " + (userDetails?.mobileNumber ?? '')}</div>
                </div>
                <div className="ContainerTwo">
                    <div>
                        <img src={emailImage} className="displyImage" />
                    </div>
                    <div className="showText">{userDetails?.userName ?? ''}</div>
                </div>
                <div className="ContainerThree" onClick={() => { window.open(userDetails?.linkedinProfile, '_blank') }}>
                    <div>
                        <img src={userDetails?.linkedinProfile?.includes('shareresume') ? require('../../assets/favicon.png') : linkedInImage} className="displyImage" />
                    </div>
                    <a href={userDetails?.linkedinProfile} target="_blank" className="showText">{userDetails?.linkedinProfile ?? ''}</a>
                </div>
            </div>

            <div className="editAndcgPwdWrapper">
                {/* <div className='preferenceView'>
                <small className="PreferenceView">Set Preference Here {"-->"}</small> <button type='button' className='prefernceBtn' onClick={getPreferenceDetails}><img src={jobPreferenceIcon} alt='jobPreferenceIcon' /> Job Preferences</button>
            </div> */}
                <div onClick={getPreferenceDetails} className="editProfileDiv">
                    {/* <img src={jobPreferenceIcon} className="editImage" /> */}
                    <JobPreference className="JobIcon" />
                    <p className="editProfile">Job Preferences</p>
                </div>
                <div onClick={() => setChangePasswordModal(true)} className="editProfileDiv">
                    <img src={lock} className="editImage" />
                    <p className="editProfile">Change Password</p>
                </div>
                <div className="editProfileDiv"
                    onClick={() => {
                        setEditProfileModal(true)
                        handleEditModal(userDetails)
                    }}
                >
                    <img src={editprofile} className="editImage" />
                    <p className="editProfile">Edit Profile</p>
                </div>
            </div>

            <Modal
                isOpen={editProfileModal}
                onRequestClose={handleCloseEditModal}
                ariaHideApp={false}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
                shouldCloseOnOverlayClick={false}
            >
                <div className="deleteModalWrapper">
                    <div className="delAccModalCrossDiv">
                        <p className="delAccModalCrossPara" onClick={handleCloseEditModal}>
                            ×
                        </p>
                    </div>
                </div>

                <div className="editProImgDiv">
                    <span>Edit Your Profile</span>
                    {/* <img src={userProfile} /> */}
                </div>

                <div className="userProfileFields">
                    <div className="userProfileFieldRow">
                        <div className="userProfileInputDiv">
                            <label>Name<span style={{ color: "#BC0000" }}>*</span></label>
                            <input
                                type="text"
                                placeholder="Enter full name"
                                value={EditUser?.fullName?.fieldValue}
                                maxLength={60}
                                onChange={(e) => {
                                    setEditUser((v) => ({
                                        ...v,
                                        fullName: onlyAlphabets('Full Name', e.target.value),
                                    }))
                                }} />
                            <p className="errorMsg">{EditUser?.fullName?.errorField}</p>
                        </div>
                        <div className="userProfileInputDiv" >
                            <label>Mobile No<span style={{ color: "#BC0000" }}>*</span></label>
                            <div className="userProfileMnoDiv">
                                <select disabled value={selectedCountryCode?.countryCode} onChange={(e) => {
                                    setSelectedCountryCode(countryCode[e.target.selectedIndex])
                                }}>
                                    {countryCode.map((item) => <option >+{item?.countryCode}</option>)}
                                </select>
                                <input
                                    type="number"
                                    placeholder="Enter mobile number"
                                    value={EditUser?.mobileNumber?.fieldValue}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            setEditUser((v) => ({
                                                ...v,
                                                mobileNumber: onlyNumber('Mobile Number', e.target.value),
                                            }))
                                        }
                                    }} />
                            </div>
                            <p className="errorMsg">{EditUser?.mobileNumber?.errorField}</p>
                        </div>
                    </div>

                    <div className="userProfileFieldRow" style={{ marginTop: "2%" }}>
                        <div className="userProfileInputDiv">
                            <label>Email Id<span style={{ color: "#BC0000" }}>*</span></label>
                            <input
                                type="email"
                                placeholder="Enter email id"
                                value={EditUser?.userName?.fieldValue}
                                onChange={(e) => {
                                    setEditUser((v) => ({
                                        ...v,
                                        userName: onEmailChange("Email", e.target.value),
                                    }))
                                }} />
                            <p className="errorMsg">{EditUser?.userName?.errorField}</p>
                        </div>

                        <div className="userProfileInputDiv" >
                            <div style={{ display: 'flex', gap: '20px', fontSize: '12px', marginTop: '3px' }}>
                                <label
                                    data-testid="linkedin-profile-label"
                                    onClick={() => {
                                        setIsLinkedIn(true)
                                        setEditUser((v) => ({
                                            ...v,
                                            linkedinProfile: { ...field, fieldValue: 'https://www.linkedin.com/in/' }
                                        }))
                                    }}
                                    style={{ display: 'flex', gap: '3px', alignItems: 'center', cursor: 'pointer' }}>
                                    {isLinkedIn ? <IoMdRadioButtonOn size={'1rem'} color='#01774A' /> : <IoMdRadioButtonOff size={'1rem'} color='#000' />}
                                    LinkedIn Profile<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                                </label>
                                <label
                                    data-testid="resume-url-label"
                                    onClick={() => {
                                        setIsLinkedIn(false)
                                        setEditUser((v) => ({
                                            ...v,
                                            linkedinProfile: { ...field, fieldValue: 'https://www.shareresume.co/#/' }
                                        }))
                                    }}
                                    style={{ display: 'flex', gap: '3px', alignItems: 'center', cursor: 'pointer' }}>
                                    {!isLinkedIn ? <IoMdRadioButtonOn size={'1rem'} color='#01774A' /> : <IoMdRadioButtonOff size={'1rem'} color='#000' />}
                                    Resume URL<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                                </label>
                            </div>
                            <input
                                type="text"
                                placeholder={isLinkedIn ? 'Enter LinkedIn Profile' : 'Enter Resume URL'}
                                value={EditUser?.linkedinProfile?.fieldValue}
                                onChange={(e) => {
                                    setEditUser((v) => ({
                                        ...v,
                                        linkedinProfile: onlyLinkedInProfile("LinkedIn Profile", e.target.value),
                                    }))
                                }} />
                            <p className="errorMsg">{EditUser?.linkedinProfile?.errorField}</p>
                            <button
                                style={{ border: 'none', outline: 'none', color: '#00000090', backgroundColor: 'transparent', display: 'flex', alignItems: 'center', alignSelf: 'end', gap: '4px', fontSize: '0.8rem' }}
                                onClick={() => { window.open(isLinkedIn ? 'https://www.youtube.com/watch?v=NMRoM0ndAtU' : 'https://www.shareresume.co/#/Dashboard', '_blank'); }}
                            >
                                <FaCircleInfo /> {isLinkedIn ? 'Help' : 'Create Resume'}
                            </button>
                        </div>
                    </div>

                    <div className="userProfileBtnDiv">
                        <button className="userProBtnOne" onClick={handleCloseEditModal}>Cancel</button>
                        <button className="userProBtnTwo" data-testid='updateProfile' onClick={() => { updateProfile() }}>
                            {/* <img src={qUpdate} alt="Something went wrong" /> */}
                            Update
                        </button>
                    </div>
                </div>
            </Modal>

            <ComponentAndScreenConstant.LoaderModal setLoader={loader} />
            <Modal
                isOpen={changePasswordModal}
                // onRequestClose={() => setChangePasswordModal(false)}
                ariaHideApp={false}
                contentLabel="Example Modal"
                overlayClassName={'modalOverlayStyle'}
                className={`modalStyle`}
            >
                <div className="passwordDiv">
                    <div className="deleteModalWrapper">
                        <div className="delAccModalCrossDiv">
                            <p className="delAccModalCrossPara" onClick={() => {
                                setChangePasswordModal(false);
                            }}>
                                ×
                            </p>
                        </div>
                    </div>

                    <div className="editProFormOuterDiv" style={{ width: window.screen.width < 1020 && "80vw" }}>
                        <h2>Change your password</h2>
                        <div className="editProField">
                            <label>Enter old password<span>*</span></label>
                            <div className="editProfileFieldDiv">
                                <input
                                    type={hidePwd.pwdOne == true ? "text" : "password"}
                                    placeholder="Old password"
                                    value={currentPassword?.fieldValue}
                                    onChange={(e) => {
                                        if (e?.target?.value.length < 17 && e.target.value != ' ') {
                                            setCurrentPassword(onPassword(e.target.value))
                                        }
                                    }} />
                                <img alt='oldEyeIcon' style={{ marginTop: hidePwd?.pwdOne == true ? "0px" : "-1%" }} onClick={() => setHidePwd({ ...hidePwd, pwdOne: !hidePwd.pwdOne })} src={hidePwd.pwdOne == true ? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" />
                            </div>
                            <p className="errorMsg">{currentPassword?.errorField}</p>

                        </div>
                        <div className="editProField">
                            <label>Enter new password<span>*</span></label>
                            <div className="editProfileFieldDiv">
                                <input
                                    type={hidePwd.pwdTwo == true ? "text" : "password"}
                                    placeholder="New password"
                                    value={newPassword?.fieldValue}
                                    onChange={(e) => {
                                        if (e?.target?.value.length < 17 && e.target.value != ' ') {
                                            setNewPassword(onPassword(e.target.value))
                                            setConfirmPassword({ ...field, fieldValue: '' })
                                        }
                                    }} />
                                <img alt='passwordEyeIcon' style={{ marginTop: hidePwd?.pwdTwo == true ? "0px" : "-1%" }} onClick={() => setHidePwd({ ...hidePwd, pwdTwo: !hidePwd.pwdTwo })} src={hidePwd.pwdTwo == true ? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" />
                            </div>
                            <p className="errorMsg">{newPassword?.errorField}</p>
                        </div>
                        <div className="editProField">
                            <label>Confirm password<span>*</span></label>
                            <div className="editProfileFieldDiv">
                                <input
                                    type={hidePwd.pwdThree == true ? "text" : "password"}
                                    placeholder="Confirm password"
                                    value={confirmPassword?.fieldValue}
                                    onChange={(e) => {
                                        if (e?.target?.value.length < 17 && e.target.value != ' ') {
                                            setConfirmPassword(matchPassword(e.target.value, newPassword?.fieldValue))
                                        }
                                    }} />
                                <img alt='confirmEyeIcon' style={{ marginTop: hidePwd?.pwdThree == true ? "0px" : "-1%" }} onClick={() => setHidePwd({ ...hidePwd, pwdThree: !hidePwd.pwdThree })} src={hidePwd.pwdThree == true ? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" />
                            </div>
                            <p className="errorMsg">{confirmPassword?.errorField}</p>
                        </div>
                        <div className="editProBtn">
                            <button className="EditProfCancelBtn" onClick={() => setChangePasswordModal(false)}>Cancel</button>
                            <button className="EdirProfChangeBtn" data-testid='updatePassword' onClick={updatePassword}>Change</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <ComponentAndScreenConstant.CommonModal
                isOpen={IsResumeAvailable}
                onRequestClose={() => setIsResumeAvailable(false)}
            >
                <div className="redirectToResume">
                    <button>x</button>
                    <Lottie
                        loop={true}
                        animationData={require('../../assets/Opps.json')}
                        className="delAccModalErrLottie"
                    />
                    <p>You don't have resume please create resume to <br />complete your profile</p>
                    <div className="resumeButtonDiv">
                        <button onClick={() => setIsResumeAvailable(false)}>
                            Cancel
                        </button>
                        <button
                            style={{ backgroundColor: '#007749', color: '#fff' }}
                            onClick={() => {
                                window.open(ResumeWebURL, '_blank')
                                setIsResumeAvailable(false)
                            }}>
                            OK
                        </button>
                    </div>
                </div>
            </ComponentAndScreenConstant.CommonModal>

            <Modal
                isOpen={preferencesModal}
                onRequestClose={() => { setPreferencesModal(false) }}
                ariaHideApp={false}
                className={`modalStyle`}
                overlayClassName={'modalOverlayStyle AlertModal'}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className='selectedPreferenceModal'>
                    <span className='closePreferenceModal' onClick={() => setPreferencesModal(false)}>X</span>
                    <h3 className='jobPreHeader'>Your Job Preferences</h3>
                    <div className='jobPreKeywordsDiv'>
                        <span>Keywords</span>
                        <span>
                            {preferenceDetails?.keywords?.map((item, i) => {
                                return (
                                    <span>{item?.name}{i == preferenceDetails?.keywords?.length - 1 ? "." : ", "}</span>
                                )
                            })}
                        </span>
                    </div>
                    <div className='jobPreKeywordsDiv'>
                        <span>Job Location</span>
                        <span>
                            {preferenceDetails?.location?.map((item, i) => {
                                return (
                                    <span>{item?.stateName}{i == preferenceDetails?.location?.length - 1 ? "." : ", "}</span>
                                )
                            })}
                        </span>
                    </div>
                    <div className='jobPreCardsDiv'>
                        <div className='jobPreCard'>
                            <div className='jobPreInnerCard'>
                                <span>Experience</span>
                                <span>{preferenceDetails?.experience?.experience}</span>
                            </div>
                        </div>
                        {preferenceDetails?.workMode?.length > 0 ?
                            <div className='jobPreCard'>
                                <div className='jobPreInnerCard'>
                                    <span>Work Mode</span>
                                    <span>
                                        {preferenceDetails?.workMode?.map((item, i) => {
                                            return (
                                                <span>{item?.workMode}{i == preferenceDetails?.workMode?.length - 1 ? "." : ", "}</span>
                                            )
                                        })}
                                    </span>
                                </div>
                            </div> : <></>

                        }
                        {preferenceDetails?.jobType?.length > 0 ?

                            <div className='jobPreCard'>
                                <div className='jobPreInnerCard'>
                                    <span>Job Type</span>
                                    <span>
                                        {preferenceDetails?.jobType?.map((item, i) => {
                                            return (
                                                <span>{item?.jobType}{i == preferenceDetails?.jobType?.length - 1 ? "." : ", "}</span>
                                            )
                                        })}
                                    </span>
                                </div>
                            </div> : <></>
                        }
                    </div>
                    <div className='jobPreModalBtns'>
                        <button id='cnclBtnPreModal' onClick={() => setPreferencesModal(false)}>Cancel</button>
                        <button
                            id='editBtnPreModal'
                            onClick={() => {
                                setPreferencesModal(false)
                                navigate('/suggestedPref', { state: { editFlow: true } })
                            }}>Edit</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default UserProfile;
