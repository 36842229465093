import React from 'react'
import './jobCard.css'
import jobIcon from '../../assets/JobIcon.svg'
import jobLocationIcon from '../../assets/jobLocationIcon.svg'
import moment from 'moment'
const jobCard = ({ jobDetails, applied, applicationStatus, isQuikHireJobs }) => {
  return (
    <div className='jobCardMainDiv'>
      <div className='jobIconDiv'>
        <img src={/* jobDetails?.institute?.instituteLogo ??  */jobIcon} alt="" />
      </div>
      <div className='jobDescriptionDiv'>
        {applied ? <span>
          {applicationStatus?.closed ? <small className='deniedStatus' style={{ color: "#BC0000" }}>Denied</small>
            : applicationStatus?.selected ? <small className='offeredStatus' style={{ color: '#FFF', background: "#01774A" }}>Offered</small>
              : applicationStatus?.interviewScheduled ? <small className='InterviewingStatus' style={{ color: '#6F00FF' }}>Interviewing</small>
                : applicationStatus?.interviewShortlisted ? <small className='ShortlistedStatus' style={{ color: '#F0B800' }}>Shortlisted</small>
                  : applicationStatus?.recruiterContacted ? <small className='contactedStatus' style={{ color: '#EA6200' }}>Contacted</small>
                    : applicationStatus?.recruiterView ? <small className='viewedStatus' style={{ color: '#5A8BFF' }}>Viewed</small>
                      : <small className='appliedStatus'>Applied</small>}
        </span>
          // }
          :
          // {isQuikHireJobs &&
          <small className='noOfOpenings'>{jobDetails?.noOfOpenings} Openings</small>
        }
        <span className='jobTitle'>{jobDetails?.designation ? jobDetails?.designation[0]?.designationName : ""}</span>
        <span className='companyName'>{jobDetails?.companyName ?? jobDetails?.company?.companyName}</span>
        <span className='skillsKeyword'>
          {/* {jobDetails?.skillString?.map((item, i) => {
            return ( */}
          <span>{jobDetails?.skill?.map((s) => s.name)?.join(', ') ?? ""}</span>
          {/* <span>{jobDetails?.skillString}</span> */}
          {/* )
          })} */}
        </span>
        <div className='jobLocation'>
          <img src={jobLocationIcon} alt="" />
          <span className='allLocations'>
            {/* {jobDetails?.state?.map((item, i) => {
            return ( */}
            <span>{jobDetails?.state?.map((s) => s.stateName)?.join(', ') ?? ""}</span>
            {/* <span>{jobDetails?.stateString}</span> */}
            {/* )
          })} */}
          </span>
        </div>
        <span className='jobTypeAndWorkType'><small className='jobType'>{jobDetails?.jobType?.jobType}</small> <small className='workType'>{jobDetails?.workMode?.workMode}</small></span>
        {/* <img src={jobIcon} alt="" /> */}
        <span className='experience'>{jobDetails?.experience?.experience}</span>
        {/* <span className='jobUploaded'>{moment(jobDetails?.postedDateTime).fromNow()}</span> */}
      </div>
    </div>
  )
}

export default jobCard