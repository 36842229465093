import './ResumeViewPage.css'
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { IoArrowBackCircle } from "react-icons/io5";
import { FiEdit3 } from "react-icons/fi";

import { ConstantScreen } from '../ConstantScreen'
import { ResumeApi } from '../../../../EnvoirnmentSetup/APIs';
import { getAPICall } from '../../../../APIMethods/APIMethods';

const ResumeViewPage = () => {
    const navigate = useNavigate()
    const [audioFile, setAudioFile] = useState('')
    const [base64Img, setBase64Img] = useState('')
    const [citySelectedValue, setCitySelectedValue] = useState({})
    const [allResumeDetails, setAllResumeDetails] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const userIDARRAY = window.location.href.split('/');
        const userID = userIDARRAY[userIDARRAY.length - 1]
        try {
            if (userID) {
                setLoader(true)
                getAPICall(ResumeApi.getResumeByUserID?.replace('{userUid}', userID))
                    .then((res) => {
                        setLoader(false)
                        const responseData = res.DATA;
                        const cityData = responseData?.city || {};

                        const updatedCity = {
                            value: cityData?.cityId,
                            label: cityData?.cityName,
                            state: cityData?.state,
                        }

                        setCitySelectedValue(updatedCity)
                        setAllResumeDetails(res.DATA);
                        setBase64Img(responseData?.base64ImageUrl)
                        setAudioFile(responseData?.summaryAudio)
                    })
                    .catch((error) => {
                        setLoader(false)
                        console.error('API call error:', error);
                    });
            }
        } catch (error) {
            console.error('Decryption error:', error);
        }
    }, []);
    const navigateToLogin = () => {
        // navigate("/login")
        const userIDARRAY = window.location.href.split('/');
        const userID = userIDARRAY[userIDARRAY.length - 1]
        navigate('/resume/Main/resumeEdit/' + userID, { state: { editResume: true } })
    }
    return (
        <div className='resumeViewShow' style={{ width: "70%", margin: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0 -10px 0', alignItems: 'center' }}>
                <button
                    title='to My Resume Details'
                    style={{ color: '#ccc' }}
                    onClick={() => { navigate('/Main/myResume') }}>
                    <IoArrowBackCircle size={'2rem'} />
                </button>
                {/* <small onClick={()=>{navigate('/Main/myResume')}}>Go Back</small> */}
                <span className='modifyBtn'> <button className='HereBtn' onClick={navigateToLogin}><FiEdit3 size={'1.25rem'} /> Edit</button> </span>
            </div>
            <div className='webPreviewDiv' style={{ width: "100%", height: "90vh", background: "#fff", margin: '10px auto' }}>
                <ConstantScreen.MobileResumePreview audioFile={audioFile} citySelectedValue={citySelectedValue} userAction={"resumeViewPage"} allResumeDetails={allResumeDetails} base64Img={base64Img} />
                <ConstantScreen.LoaderModal setLoader={loader} />
            </div>
        </div>
    )
}

export default ResumeViewPage