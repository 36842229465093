import DatePickerField from "../../../Components/DatePickerField/DatePickerField";
import MobileResumePreview from "./MobileResumePreview/MobileResumePreview";
import LoaderModal from "../../../Components/LoaderModal/LoaderModal";
import SelectField from "../../../Components/SelectField/SelectField";
import InputText from "../../../Components/InputText/InputText";
import ClosingDetails from "./ClosingDetails/ClosingDetails";
import WorkExperience from "./WorkExperience/WorkExperience";
import AddField from "./AddField/AddField";
import TextArea from "./TextArea/TextArea";
import Modal from "./Modal/Modal";
import Web from "../Web/Web";
import InputFile from "./InputFile/InputFile";
import ToggleSwitch from "./ToggleSwitch/ToggleSwitch";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import InterShipDetails from "./InterShipDetails/InterShipDetails";
import MobileNumberField from "./MobileNumberField/MobileNumberField";
import ShowGeneratedResume from "./ShowGeneratedResume/ShowGeneratedResume";
import ResumeViewPage from "./ResumeViewPage/ResumeViewPage";
import Mobile from "../Mobile/Mobile";
import EducationalDetails from "./EducationalDetails/EducationalDetails";
import CertificationDetails from "./CertificationDetails/CertificationDetails";

export const ConstantScreen= {
    InputText,
    InputFile, 
    TextArea, 
    AddField,
    PersonalDetails, 
    WorkExperience,
    MobileNumberField, 
    EducationalDetails, 
    CertificationDetails,
    SelectField,
    ClosingDetails,
    DatePickerField,
    MobileResumePreview,
    ShowGeneratedResume, 
    ToggleSwitch, 
    Web,
    Mobile,
    Modal, 
    InterShipDetails, 
    LoaderModal,
    ResumeViewPage,
}