import './InputFile.css'
import React from 'react'
import Upload from '../../../../assets/Upload.svg'

const InputFile = ({
  labelTxt,
  inputType,
  inputClass,
  inputValue,
  onChange,
  inputStyle,
  acceptInput,
  inputWrapperStyle,
  readOnly,
  errMsg,
  inputId,
  placeholderTxt,
  isRequired = false,
  s3BucketFileName = ""
}) => {
  return (
    <div className={inputWrapperStyle} style={{ display: "flex", flexDirection: "column" }}>
      {!!labelTxt && <label className='inputLable' htmlFor={'test'}>{labelTxt}{isRequired && <small style={{ color: "#BC0000" }}>*</small>}</label>}
      <div className='profileDiv'>
        <label className='profileImagemain'>
          <img src={Upload} alt="" />
          <small className='inputFileName'>
            {!!inputValue ? inputValue?.name ?? s3BucketFileName : placeholderTxt}
          </small>
          {!!inputValue &&
            <small
              onClick={(e) => {
                e.stopPropagation();
                onChange(null)
              }}
              style={{ color: "red", fontWeight: "500", cursor: "pointer", zIndex: 1 }}>
              X
            </small>}
        </label>
        <input type={'file'} id={inputId} style={{ opacity: 0, width: "88%" }} title={""} accept={acceptInput} readOnly={readOnly} className={inputClass} placeholder={placeholderTxt} onChange={(e) => onChange(e?.target?.files[0])} />
      </div>
      {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
    </div>
  )
}

export default InputFile