export const field = {
    fieldValue: '',
    isValidField: true,
    errorField: ""
};

export const CheckImage = /^(https?:)?\/\/[^"'\s]+\.(png|jpe?g|gif)$/i;

const GSTRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}Z[0-9A-Z]{1}$/;
const CharacterRegex = /^[a-zA-Z ]+$/;
const CharacterRegexWithoutSpace = /^[a-zA-Z]+$/;
const NumberRegex = /^[0-9]\d*$/;
const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; //---new 
// const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/; //---old
const AddressRegex = /^(?!\d+$)[a-zA-Z0-9&() ]*$/;

export const ReturnValue = (value, regex, name = '') => {
    let val = value?.trim()
    let newField = {
        fieldValue: value,
        isValidField: (val?.length > 0) ? (!!regex ? (regex?.test(val) ? true : false) : true) : false,
        errorField: (val?.length > 0) ? (!!regex ? (regex?.test(val) ? '' : !!name ? `Invalid ${name}` : `Invalid Input`) : '') : !!name ? `${name} is required` : `This field is required`
    }
    return newField
}

export const validateReturnValue = (value, isValid, error) => {
    return {
        fieldValue: value,
        isValidField: isValid,
        errorField: error
    }
}

export const anythingExceptOnlySpace = (name, val) => {
    return ReturnValue(val)
}

export const GSTValue = (name, val) => {
    return ReturnValue(val, GSTRegex, name)
}

//validation only allow alphabates, required field and apply regex according to field name
export const onlyAlphabets = (name, val) => {
    let regex;
    switch (String(name)?.toLowerCase()) {
        case "promocode name":
            return ReturnValue(val, AddressRegex, name)
        case "email address":
            return ReturnValue(val, EmailRegex, name)
        case "expiry date":
            regex = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
            return ReturnValue(val, regex, name)
        case "address":
            return ReturnValue(val, AddressRegex, name)
        case "postgraduniversity":
            return ReturnValue(val, CharacterRegexWithoutSpace, name)
        default:
            return ReturnValue(val, CharacterRegex, name)
    }
};

export const onEmailChange = (name, val) => {
    return ReturnValue(val, EmailRegex, name)
}

//validation only allow number, required field and length of the number
export const onlyNumber = (name, val) => {
    const regex = /^[0-9]*$/;
    if (!regex.test(val)) {
        val = val.replace(/[^0-9]/g, '');
    }
    return ReturnValue(val, NumberRegex, name)
}

export const onlyPincode = (val, len = 6, name = null) => {
    if (val.length <= len) {
        return ReturnValue(val, NumberRegex, name)
    }
}

export const AddressValidation = (val) => {
    return ReturnValue(val)
}

export const onlyAlphabetsWithAnd = (name, val) => {
    let regex = /^[a-zA-Z& ]+$/;
    return ReturnValue(val, regex, name)
}

export const onlyAlphabetsWithComa = (name, val) => {
    let regex = /^[a-zA-Z&, ]+$/;
    return ReturnValue(val, regex, name)
}
export const allowAllCharacters = (name, val) => {
    return ReturnValue(val)
}

export const alphabetsWithNotOnlyNumber = (name, val) => {
    let regex = /^(?=.*[0-9])[a-zA-Z0-9]+$/;
    return ReturnValue(val, regex, name)
}

export const MyValidation = (regex, name, val) => {
    return ReturnValue(val, regex, name)
}

export const onlyAlphabetsWithRoundBracketComma = (name, val) => {
    let regex = /^[(),a-zA-Z& ]+$/;
    return ReturnValue(val, regex, name)
}

export const jobDescription = (name, val) => {
    const descRegex = /^(?!^[0-9]*$)(?!^[^\w\s]*$)(?!^\.$)(?=.*[A-Za-z])[\w\W]*$/
    return ReturnValue(val, descRegex, name)
}
/////////////////////////////////////////////////////////////////////////////////////////////.............................................................................................................

export const companyValidation = (name, val) => {
    let NameRegex
    if (name == "Company Website Link") {
        // NameRegex = /^(https:\/\/)?www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
        NameRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\/?$/
    } else if (name == "Company Description" || name == "Corporate Address") {
        NameRegex = /^(?!^[0-9]*$)(?!^[^\w\s]*$)(?!^\.$)(?=.*[A-Za-z])[\w\W]*$/
    } else {
        NameRegex = /^(?=.*[a-zA-Z])([a-zA-Z0-9&.\s]+)$/
    }

    if (val?.trim().length !== 0) {
        if (NameRegex?.test(val?.trim())) {
            if (name == "Company Website Link") {
                if (val.includes("www.")) {
                    let cutout = val.substring(val.indexOf("www.") + 4)
                    if (NameRegex.test(cutout)) {
                        return validateReturnValue(val, true, '')
                    } else {
                        return validateReturnValue(val, false, 'Invalid input')
                    }
                } else {
                    return validateReturnValue(val, true, '')
                }
            } else {
                return validateReturnValue(val, true, '')
            }
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    } else {
        return validateReturnValue(val, false, `${name} is required`)
    }
}

export const onlyLinkedInProfile = (name, val) => {
    let linkedinRegex;
    if (val?.toLowerCase()?.includes("link")) {
        linkedinRegex = /^(https?:\/\/)?(www\.|in\.)?linkedin\.com\/(?:[a-zA-Z]{2,}\/)?(in|pub|company|groups)\/[a-zA-Z0-9_-]+\/?(?:\?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=(android_app|ios_app))?$/i;
    } else {
        linkedinRegex = /^((http:\/\/www\.shareresume\.co\/#\/|https:\/\/www\.shareresume\.co\/#\/|https:\/\/shareresume\.co\/#\/|www\.shareresume\.co\/#\/|shareresume\.co\/#\/)|(http:\/\/13\.52\.157\.11:8080\/resume_builder_int\/#\/))[a-zA-Z0-9_-]/;
    }
    if (val?.trim()?.length !== 0) {
        if (linkedinRegex?.test(val.trim())) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    } else {
        return validateReturnValue(val, false, name !== null ? `${name} is required` : `This field is required`)
    }
}

export const Calendlyaccount = (name, val) => {
    if (!(!!val?.trim()) || val?.trim() === "https://www.calendly.com" || val?.trim() === "https://calendly.com") {
        return validateReturnValue(val, true, '')
    } else {
        if (/^(https?:\/\/)?(www\.)?calendly\.com\/[a-zA-Z0-9_-]+$/i.test(val.trim())) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    }

}

export const onPassword = (val) => {
    if (val?.trim().length !== 0) {
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,16}$/.test(val)) {
            // if (/^(?=.*\d)(?=.*[a-zA-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,20}$/.test(val)) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Password must contain at least 1 lower case, 1 upper case, 1 numeric, special character, length maximum of 16 and minimum of 8')
        }
    } else {
        return validateReturnValue(val, false, 'This Field is required value must be start with character or number.')
    }
}

export const onlySalaryEntry = (field, val) => {
    if (val?.length == 0) {
        return validateReturnValue(val, true, '')
    }
    else if (/^(?!-)(?!.*[-.]{2})[0-9]+(?:\.[0-9])?(?:-[0-9]+(?:\.[0-9])?)?$/.test(val)) {
        return validateReturnValue(val, true, '')
    } else {
        return validateReturnValue(val, false, 'Invalid Salary details')
    }
}

export const matchPassword = (val1, val2) => {
    if (val1 === val2) {
        return validateReturnValue(val1, true, '')
    } else {
        return validateReturnValue(val1, false, 'Passwords did not match')
    }
}

export const nonRequiredNum = (val, len) => {
    if (val !== '' || val?.trim().length !== 0) {
        if (/^[1-9]\d*$/.test(val?.trim()) && val?.trim().length === len) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    } else {
        return validateReturnValue(val, true, '')
    }
}

export const emailValidationForJobPoster = (name, val) => {
    // const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|gmail\.|hotmail\.)(?!yahoo|gmail|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|hotmail\.)(?!yahoo|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // const onlyCorporateMails = /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; //---old

    if (val?.trim().length !== 0) {
        if (onlyCorporateMails.test(val?.trim())) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    } else {
        return validateReturnValue(val, false, name !== null ? `${name} is required` : `This field is required`)
    }
}

export const isMobileNumber = (val) => {
    return /^(?:\+?\d{1,4})?[.\-\s]?\(?\d{1,4}\)?[.\-\s]?\d{1,4}[.\-\s]?\d{1,9}$/i.test(val)
}
export const isEmail = (val) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(val.toLocaleLowerCase().replace('mailto:', ''))
}
export const isLink = (val) => {
    return /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w\-\.]*)*(\?[^\s]*)?$/i.test(val)
}
